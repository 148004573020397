'use strict'

import { Component } from 'react';
import Browser from '../../../components/Search/Browser.react';
import SearchKeywords from '../../../components/Search/Filters/SearchKeywords.react';

import './ArticleList.scss';

export default class ArticleList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            params: this.getParamsFromProps(props),
            custom_tag: null,
        };
    }

    getParamsFromProps(props) {
        const defaultTags = props.module.data && props.module.data.default_tags || []
        return {
            types: ['page'],
            filters: {'page_type': 'article', 'tags': defaultTags},
            sort_by: 'published',
            sort_order: 'desc',
        };
    }

    onChangeParams = (params) => {
        this.setState({params});
    }

    toggleSelectableFilter = (tagName) => {
        const { custom_tag , params } = this.state
        // When there is already a custom tag remove the previous custom tag
        const newTags = custom_tag ? params.filters.tags.slice(0, -1) : params.filters.tags;
        const shouldRemoveCustomTag = tagName === "All" || custom_tag === tagName;

        this.setState(prevState => ({
            params:{
                filters: {
                    'page_type': prevState.params.filters.page_type,
                    'tags': shouldRemoveCustomTag ? newTags : [...newTags,tagName],
                  }
            },
            'custom_tag': shouldRemoveCustomTag ? null : tagName
        }))
    }

    displayTag = (tagName, index) => {
        const { custom_tag } = this.state
        const isActive = custom_tag === tagName || (custom_tag === null && tagName === "All")
        return(
            <li>
                <button key={index} className="article-filter-button" onClick={() => this.toggleSelectableFilter(tagName)} data-active={isActive}>
                    {tagName}
                </button>
            </li>
         )
    }

    render = () => {
        const { params } = this.state;
        const { module } = this.props;

        return (
            <div className="landing-module article-list-module">
                <SearchKeywords params={params}
                    onChangeParams={this.onChangeParams}
                    className="full-browser-keyword-search landing-keyword-search"
                    placeholder="Search articles..." />
                {module.data && module.data.selectable_tags && module.data.selectable_tags.length > 1 ?
                    <div className="doc-type-params">
                        <ul>{["All",...module.data.selectable_tags].map(this.displayTag)}</ul>
                    </div>
                : null}
                <Browser contextName="Article"
                    params={params}
                    loadingMessage="Loading articles..."
                    allowedTypes={['page']}
                    showTypePicker={false}
                    onChangeParams={this.onChangeParams} />
            </div>
        );
    }
}
