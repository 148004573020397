'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './AppHomeScreen.scss';

const loginFormOpts = {
    hideIDPs: true,
    hideSignUp: true,
    hideRetrieveInvite: false,
};


export default class AppHomeScreen extends Component {
    static contextTypes = {
        showLoginForm: PropTypes.func,
    };

    componentDidMount = () => {
        const { location } = this.props;
        const { showLoginForm } = this.context;

        if (location.pathname !== '/') {
            showLoginForm(loginFormOpts);
        }
    }

    onClickSignUp = () => {
        const { showLoginForm } = this.context;

        showLoginForm({
            ...loginFormOpts,
            defaultMode: 'retrieve-invite',
            retrieveInviteText: 'Invited by your dietitian, fitness professional or wellness organization?',
            backToSignIn: 'Sign In Instead',
            forgotPasswordContext: 'native-app',
        });
    }

    render() {
        const { showLoginForm } = this.context;
        const { children, location } = this.props;

        return (
            <div className="app-home-screen">
                <div className="app-home-screen-outer-container">
                    <div className="app-home-screen-inner-container">
                        <header>
                            <h2>Welcome to</h2>
                            <img src="https://static.chewba.info/images/eatlove-logo-navy-blue-green-2017.svg" />
                        </header>
                        <footer>
                            <button className="sign-in-btn" onClick={() => showLoginForm({...loginFormOpts})}>Sign In</button>
                            <button className="sign-up-btn" onClick={this.onClickSignUp}>Sign Up</button>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}
