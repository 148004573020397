'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Modal from 'react-modal';

import PlanEditor from '../../pro/components/Plans/Editor.react';
import PlanCard from "../../components/Dashboard/FeaturedPlans/PlanCard.react";
import UserStore from '../../stores/UserStore';
import AuthStore from '../../stores/AuthStore';
import { getConfig } from '../../utils/Env';

import styles from './Viewer.scss';

export default class Viewer extends Component {

    static contextTypes = {
        isPro: PropTypes.bool,
        showLoginForm: PropTypes.func,
        confirm: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        const user = UserStore.getUser();

        if (!user) {
            this.loadPlan(props.params.uuid)
        }

        this.state = {
            user,
            plan: null, 
            loading: true,
        };
    }

    componentDidMount = () => {
        const { params } = this.props
        const { user } = this.state;
        const { isPro, confirm } = this.context;

        UserStore.addChangeListener(this.onUserStoreChange);

        if (user && user.dietitian && !isPro) {
            const proUrl = 'https://' + getConfig('pro_host') + '/menus/' + params.uuid;

            confirm(
                <p className="t3">We noticed you have both an EatLove PRO account and client account.  Would you like to open this using your PRO account?</p>,
                accept => window.open(proUrl, '_blank'),
                reject => false,
                {acceptText: 'Yes, go to EatLove PRO', rejectText: 'No, use my client account'}
            );
        }
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onUserStoreChange = () => {
        this.setState({
            user: UserStore.getUser()
        });
    }

    loadPlan = (uuid) => {
             AuthStore.fetch(getConfig('recipe_api') + '/plans/' + uuid + '?embed=assets', null, true).then(
                plan => {
                    this.setState({plan, loading: false})
                },
                error => {
                    this.setState({loading: false, isModalOpen: true});
                }
            );   
    }


    onClickSignIn = () => {
        const { showLoginForm } = this.context;

        showLoginForm();
        this.closeModal();
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    renderSignInModal = () => {
        const { params } = this.props;
        const { isModalOpen } = this.state;

        if (!isModalOpen) {
            return;
        }

        const proUrl = 'https://' + getConfig('pro_host') + '/menus/' + params.uuid;
        const consumerUrl = 'https://' + getConfig('self_host') + '/menus/' + params.uuid;

        return (
            <Modal isOpen={true}
                className="el-modal el-modal3 el-modal3-fixed"
                overlayClassName="el-modal-overlay"
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Open Collection"
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal3-container collection-sign-in-modal ">
                    <header>
                        <h2>Sign in to view the plan</h2>
                    </header>
                    <footer>
                        <a className="el-medium-btn el-medium-btn el-raspberry-btn" onClick={this.onClickSignIn}>Sign In EatLove</a>
                        <a className="el-medium-btn el-medium-btn el-raspberry-outline-btn" target="_blank" href={proUrl} >Sign In EatLove PRO (for professionals)</a>
                    </footer>
                </div>
            </Modal>
        );
    }


    renderPlanPreview = () => {
        const { plan, loading } = this.state;


        return (
            <div data-loading={loading} className="patient-recommend-plans" >
                {loading ? <div className="loading"><p>loading...</p><i className="icon-spinner2" /></div> : null}
                {plan ? 
                    (<div className="new-plan-mode">

                        <div className="plan-title">
                            <h3 onClick={() => this.setState({isModalOpen: true})}>{plan.title}</h3>
                            <button className="el-medium-btn el-medium-wide-btn el-raspberry-btn" onClick={() => this.setState({isModalOpen: true})}>VIEW THE PLAN</button>
                        </div>

                        <ul className="plan-size">
                            {plan.breakfasts ? (
                                <li onClick={() => this.setState({isModalOpen: true})}>
                                    {plan.breakfasts}{" "}
                                    {plan.breakfasts == 1 ? "Breakfast" : "Breakfasts"}
                                </li>
                            ) : null}
                            {plan.lunches ? (
                                <li onClick={() => this.setState({isModalOpen: true})}>
                                    {plan.lunches} {plan.lunches == 1 ? "Lunch" : "Lunches"}
                                </li>
                            ) : null}
                            {plan.dinners ? (
                                <li onClick={() => this.setState({isModalOpen: true})}>
                                    {plan.dinners} {plan.dinners == 1 ? "Dinner" : "Dinners"}
                                </li>
                            ) : null}
                        </ul>

                        <PlanCard  onClick={() => this.setState({isModalOpen: true})} plan={plan} hideTitle={true} showAdvancedMode={false} />
                    </div>)
                : null}
            </div>
        )
    }

    render = () => {
        const { isPro } = this.context;
        const { params, location } = this.props;
        const { user } = this.state;

        return (
            <div className="customizer-page">
                {!isPro ? <Link to="/">
                    <img className="eatlove-logo-header" src="https://static.chewba.info/images/header-logo-gray-green-2017-07-03.png" />
                </Link> : null}
                {user ? <PlanEditor uuid={params.uuid}
                    location={location} hideWarnings={isPro}
                    firstRun={location && location.query && location.query.signup ? true : false}
                    showAddToPlanner={true} showConsumerFavoriteButton={true} />
                : this.renderPlanPreview()}
                {this.renderSignInModal()}
            </div>
        );
    }
}
