
'use strict';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import assign from 'object-assign';

import AuthStore from './AuthStore';
import FulfillmentProviderConstants from '../constants/FulfillmentProviderConstants';

import { getConfig } from '../utils/Env';

const CHANGE_EVENT = 'change';

let _store = {
    fulfillmentProviders: [],
    loaded: false,
    loading: false,
};

function processLoadResponse(response) {
    if (!(response && response.elements)) {
        return;
    }

    _store.fulfillmentProviders = response.elements;
}

function loadFulfillmentProvider(postalCode) {
    return new Promise(async (accept, reject) => {
        const response = await AuthStore.fetch({url: getConfig('recipe_api') + '/providers?postal_code=' + (postalCode || '')}, {}, true);

        processLoadResponse(response);

        accept();
    });
}

var FulfillmentProviderStore = assign({}, EventEmitter.prototype, {
    getFulfillmentProviders: function() {
        return _store.fulfillmentProviders || [];
    },

    isLoaded: function() {
        return _store.loaded || false;
    },

    isLoading: function() {
        return _store.loading || false;
    },

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },
});

export default FulfillmentProviderStore;

AppDispatcher.register((payload) => {
    const { actionType, postalCode } = payload.action;

    switch (actionType) {
        case FulfillmentProviderConstants.FULFILLMENT_PROVIDER_LOAD:
            if (_store.loaded || _store.loading || !postalCode) {
                return;
            }

            _store.loading = true;

            loadFulfillmentProvider(postalCode).then(() => {
                _store.loading = false;
                _store.loaded = true;
                FulfillmentProviderStore.emitChange();
            });
            break;

        case FulfillmentProviderConstants.FULFILLMENT_PROVIDER_RELOAD:
            _store.loading = true;
            loadFulfillmentProvider(postalCode).then(() => {
                _store.loading = false;
                _store.loaded = true;
                FulfillmentProviderStore.emitChange();
            });
            break;

        case FulfillmentProviderConstants.FULFILLMENT_PROVIDER_HYDRATE:
            _store.fulfillmentProviders = payload.action.providers;

            FulfillmentProviderStore.emitChange();
            break;

    }
});
