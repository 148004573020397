'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ImgResized from '../../components/Widgets/ImgResized.react';
import Header from '../../components/Article/Header.react';
import Footer from '../../components/Widgets/footer/Footer.react';
import CategoryHero from './Landing/CategoryHero.react';
import ArticleList from './Landing/ArticleList.react';

import './Landing.scss';

export default class Landing extends Component {

    renderBlock = (module, i) => {
        if (module.type === 'category-hero') {
            return <CategoryHero module={module} key={i} />
        }

        if (module.type === 'article-list') {
            return <ArticleList module={module} key={i} />
        }
    }

    render = () => {
        const { page } = this.props;

        return (
            <div className="landing-page">
                <Header />

                {page ?
                    <h1>{page.title}</h1>
                : null}

                {page && page.contents && page.contents.draftjs ?
                    <div className="landing-modules">
                        {page.contents.draftjs.blocks.map(this.renderBlock)}
                    </div>
                : null}

                <Footer />
            </div>
        );
    }
}
