'use strict'

import { render } from 'react-dom'
import React from 'react'
import { Link } from 'react-router';

import './HowItWorks.scss';

export default function HowItWorks(props) {
    return (
        <div className="banner-how-it-works">
            <header>
                <h2>{props.title || 'How It Works'}</h2>

                <p>{props.subtitle || <span><strong>Try EatLove for 14 days free.</strong> <br /> No credit card required.</span>}</p>
            </header>

            <iframe src="https://fast.wistia.net/embed/iframe/o0p43aidae?seo=false&videoFoam=true" frameBorder="0" allowFullScreen allow="autoplay"></iframe>

            <section className="how-step">
                <div className="illustration">
                    <i className="icon-personalize"></i>
                </div>

                <h4>1. Personalize</h4>
                <p>Tell us about your health goals and any medical conditions along with your taste preferences.</p>
            </section>

            <section className="how-step">
                <div className="illustration">
                    <i className="icon-plate2"></i>
                </div>

                <h4>2. View Meal Recommendations</h4>
                <p>We tell you exactly what to eat and when. Swap out what you don’t like. We’ll show you home cooked and restaurant items that keep you within your nutrition goals.</p>
            </section>

            <section className="how-step">
                <div className="illustration">
                    <i className="icon-heart3"></i>
                </div>

                <h4>3. Get Healthy</h4>
                <p>You get recipes, grocery lists and optional delivery through Walmart, AmazonFresh or Instacart right to your door.</p>
            </section>

            {props.children}
        </div>
    );
}

