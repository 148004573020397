'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import RecipeDetailsModal from '../../components/Search/Modals/RecipeDetailsModal.react';

import './Details.scss';

export default class Details extends Component {
    static propTypes = {
        params: PropTypes.object,
    };

    render = () => {
        const { params } = this.props;

        return (
            <RecipeDetailsModal uuid={params.uuid} />
        );
    }
}
