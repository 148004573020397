'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import FoodDetailsModal from '../../components/Search/Modals/FoodDetailsModal.react';
import UserStore from '../../stores/UserStore';

import './Details.scss';

export default class Details extends Component {
    static propTypes = {
        params: PropTypes.object,
    };

    static childContextTypes = {
        // User assets
        user: PropTypes.object,
    };

    getChildContext = () => {
        return {
            user: UserStore.getUser()
        };
    }

    render = () => {
        const { params } = this.props;

        return (
            <FoodDetailsModal uuid={params.uuid} />
        );
    }
}
