'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import url from 'url';

import ImgResized from '../../components/Widgets/ImgResized.react';
import Header from '../../components/Article/Header.react';
import Footer from '../../components/Widgets/footer/Footer.react';
import Analytics from '../../utils/Analytics';

import { getConfig } from '../../utils/Env';

import './Article.scss';

export default class Article extends Component {
    componentDidMount() {
        const { page } = this.props;

        if (page && page.title) {
            Analytics.viewBlog({
                "Blog Title": page.title
            });
        }
    }

    renderByLine = (page) => {
        let { name = 'EatLove', href, image } = page.author;

        const parsed = href ? url.parse(href) : null;

        name = <span className="name">{name}</span>

        if (parsed && (parsed.hostname === getConfig('www_host') || parsed.hostname === 'www.eatlove.is')) {
            name = <Link to={parsed.path}>{name}</Link>
        } else if (href) {
            name = <a target="_blank" href={href}>{name}</a>
        }

        return (
            <div className="by-line">
                {image
                    ? <ImgResized className="avatar" src={image} width={50} height={50} />
                    : <i className="avatar icon-logo2"><span className="path1" /><span className="path2" /></i>
                }

                {name}

                {page.publish_on ?
                    <span className="publish-date">{moment(page.publish_on).format('MMM Do, YYYY')}</span>
                : null}
            </div>
        );
    }

    renderDefaultByLine = () => {
        const { page } = this.props;

        return this.renderByLine({...page, author: {name: 'Eatlove'}});
    }

    render = () => {
        const { page } = this.props;

        return (
            <div className="article-page">
                <Header />

                {page ? <h1>{page.title}</h1> : null}

                {(page.author && (page.author.name || page.author.href))
                    ? this.renderByLine(page)
                    : this.renderDefaultByLine()}

                {page && page.contents ?
                    <div className="article-rich-text" dangerouslySetInnerHTML={{__html: page.contents.html}} />
                : null}

                <Footer />
            </div>
        );
    }
}
