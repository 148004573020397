'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import store from 'store';
import debounce from 'lodash.debounce';
import { Link } from 'react-router';

import AuthStore from '../../stores/AuthStore';
import UserActions from '../../actions/UserActions';
import { getConfigurationIssues, getMealSearchParamsForProfile } from '../../pro/utils/Patients';
import { getConfig } from '../../utils/Env';

import allMealTypes from '../../tables/meal-types';

import './ProfileDoctorModal.scss';
import '../Preferences/Avoidances.scss';
import Analytics from '../../utils/Analytics';

export default class ProfileDoctorModal extends Component {
    static propTypes = {
    };

    static contextTypes = {
        user: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            drawer: false,
            culprit: {reason: null},

            preferences: context.user.preferences || {},
        };

        // Don't re-run analysis for a half second after inhibited
        this.debounceUpdateParent = debounce(this.updateParent, 500);
    }

    componentDidMount = async () => {
        try {
            this.setState({drawer: true});
            await this.analyzeProfile();
        } catch (error) {
            this.setState({error: (error && error.message) || error || 'unknown error'});
        }
    }


    componentDidUpdate(prevProps, prevState) {
        const { culprit } = this.state;
        const { error } = this.props;

        if (prevState.culprit !== culprit) {
            Analytics.planGenerationError({culprit, error});
        }
    }

    whichMealsAreIncluded = (profile) => {
        const { preferences = {}, family = [] } = profile || {};

        let breakfasts = preferences.breakfasts,
            lunches = preferences.lunches,
            dinners = preferences.dinners,
            snacks = preferences.snacks;

        family.forEach(member => {
            breakfasts = breakfasts || member.breakfasts;
            lunches = lunches || member.lunches;
            dinners = dinners || member.dinners;
            snacks = snacks || member.snacks;
        });

        return { breakfasts, lunches, dinners, snacks };
    }

    analyzeProfile = async () => {
        const { preferences } = this.state;
        const { user } = this.context;

        this.setState({loading: true});

        if (!preferences) {
            return this.setState({loading: false});
        }

        if ((preferences.limit_tags || []).includes('Basic')) {
            return this.setState({culprit: {reason: 'Basic'}, loading: false});
        }

        const { breakfasts, lunches, dinners, snacks } = this.whichMealsAreIncluded(user);

        if (snacks && !breakfasts && !lunches && !dinners) {
            return this.setState({culprit: {reason: 'meals are turned off'}, loading: false});
        }

        if (breakfasts && lunches && dinners && !snacks) {
            return this.setState({culprit: {reason: 'snacks'}, loading: false});
        }

        const { warnings, errors } = getConfigurationIssues(user);

        // If there are errors, set them immediately and no need to continue.
        if (errors.length) {
            return this.setState({culprit: {reason: 'profile-error', errors}, loading: false});
        }

        const qualifying = {};

        for (const { threshold, mealType, plural, prop } of allMealTypes) {
            if (!user.preferences[prop]) {
                continue;
            }

            const params = getMealSearchParamsForProfile(mealType, user);

            params.size = 0; // we don't need results, we just want to know the total

            const response = await AuthStore.fetch(getConfig('recipe_api') + '/search', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
                body: JSON.stringify(params),
            });

            qualifying[mealType] = {
                total: response.total,
                params,
            };

            if (response.total && response.total < threshold) {
                warnings.push(`Your profile matches very few ${plural.toLowerCase()}. We may not be able to build a meal plan matching your profile.`);
            } else if (!response.total) {
                errors.push(`No ${plural.toLowerCase()} could be found that match your profile, we will not be able to build a meal plan matching your profile.`);
            }
        }

        if (warnings.length || errors.length) {
            return this.setState({culprit: {reason: 'profile-error', errors, warnings}, loading: false});
        }

        this.setState({culprit: {reason: 'unknown'}, loading: false});
    }

    updateParent = () => {
        const { preferences } = this.state;

        UserActions.updateSpecificMeta({preferences});
    }

    closeModal = () => {
        this.setState({drawer: false}, () => setTimeout(this.props.closeModal, 333));
    }

    retryPopulate = () => {
        const { retry } = this.props;

        retry();
    }

    toggleBasicOffAndRetry = () => {
        let { preferences } = this.state;

        preferences = preferences || {};
        let { limit_tags = [] } = preferences;

        if (limit_tags.includes('Basic')) {
            limit_tags.splice(limit_tags.indexOf('Basic'), 1);
        } else {
            limit_tags.push('Basic');
        }

        preferences.limit_tags = limit_tags;

        UserActions.updateSpecificMeta({preferences}).then(this.retryPopulate);
    }

    toggleMealsOnAndRetry = () => {
        const { user } = this.context;
        let { preferences } = this.state;

        preferences = preferences || {};
        preferences.breakfasts = user.shopping_freq || 5;
        preferences.lunches = user.shopping_freq || 5;
        preferences.dinners = user.shopping_freq || 5;
        preferences.snacks = user.shopping_freq || 5;

        UserActions.updateSpecificMeta({preferences}).then(this.retryPopulate);
    }

    toggleSnacksOnAndRetry = () => {
        const { user } = this.context;
        let { preferences } = this.state;

        preferences = preferences || {};
        preferences.snacks = user.shopping_freq || 5;

        UserActions.updateSpecificMeta({preferences}).then(this.retryPopulate);
    }

    onChangePreferences = (user, preferences) => {
        this.setState({preferences}, this.debounceUpdateParent);
    }

    onChangeProfile = ({preferences}) => {
        this.setState({preferences}, this.debounceUpdateParent);
    }

    renderCulprit = () => {
        const { culprit, loading } = this.state;
        const { user } = this.context;
        const { preferences = {} } = user || {};
        const { retryCount, closeModal } = this.props;

        const { my_dietitian, practice_type = 'consumer' } = user;
        const { reason = 'unknown' } = culprit;

        if (loading) {
            return (
                <div className="profile-doctor preferences-form">
                    <div className="scroll-container">
                        <p>Analyzing your profile...</p>
                    </div>
                </div>
            );
        }

        if (reason == 'meals are turned off') {
            return (
                <div className="profile-doctor preferences-form">
                    <div className="scroll-container">
                        <p>
                            We’re sorry, we were unable to build a meal plan due to too few meals in a day.
                            Please enable breakfast, lunch and dinner options and try again.
                        </p>
                    </div>

                    <footer>
                        {(retryCount > 0) ?
                            <button className="close-modal-btn" onClick={this.closeModal}>
                                close
                            </button>
                        : null}

                        <button className="retry-btn" onClick={this.toggleMealsOnAndRetry}>Enable meals and retry</button>
                    </footer>
                </div>
            );
        }

        if (reason == 'Basic') {
            return (
                <div className="profile-doctor preferences-form">
                    <div className="scroll-container">
                        <p>We are unable to recommend Simple Recipes that meet your nutrition requirements. </p>
                        <p><em>The Simple Recipes setting has been turned OFF in order to create your nutritious meal recommendations.</em></p>
                    </div>

                    <footer>
                        {(retryCount > 0) ?
                            <button className="close-modal-btn" onClick={this.closeModal}>
                                close
                            </button>
                        : null}

                        <button onClick={this.toggleBasicOffAndRetry} className="retry-btn">Retry</button>
                    </footer>
                </div>
            );
        }

        if (reason == 'snacks') {
            return (
                <div className="profile-doctor preferences-form">
                    <div className="scroll-container">
                        {retryCount == 0
                            ? <p>We’re sorry, we are unable to find meals that meet your nutrition requirements due to snacks being excluded.</p>
                            : <p>We’re sorry, we are still unable to find meals that meet your nutrition requirements due to snacks being excluded.</p>}

                        <p><em>Snacks are an essential component of a day full of meals. We've turned snacks on for you.</em></p>
                    </div>

                    <footer>
                        {(retryCount > 0) ?
                            <button className="close-modal-btn" onClick={this.closeModal}>
                                close
                            </button>
                        : null}

                        <button className="retry-btn" onClick={this.toggleSnacksOnAndRetry}>Retry</button>
                    </footer>
                </div>
            );
        }

        let /* 's do */ getHelp = ( // No, it's embarrasing...
            <div>
                <p>Please try removing unnecessary avoidances, increasing leftovers, or relaxing prep time limits and then retrying.</p>
                <p>If the problem persists, please contact <a href="mailto:support@eatlove.is?subject=Unable to find Meals">support@eatlove.is</a> for assistance</p>
            </div>
        ) // Not for me it's not! ;)

        if (practice_type === 'dietetics' && my_dietitian && my_dietitian.email !== 'redacted') {
            getHelp = (
                <div>
                    <p>Please try removing unnecessary avoidances, increasing leftovers, or relaxing prep time limits and then retrying.</p>
                    <p>If the problem persists, please contact <a href={`mailto:${my_dietitian.email}?subject=Unable to find Meals`}>{my_dietitian.name}{my_dietitian.credentials ? ', ' : null} <em>{my_dietitian.credentials}</em></a> for assistance.</p>
                </div>
            );
        }

        if (reason === 'profile-error') {
            const { errors = [], warnings = [] } = culprit;

            return (
                <div className="profile-doctor preferences-form">
                    <div className="scroll-container">
                        {errors && errors.length ?
                            <ul className='config-error'>
                                <li>{errors[0]}</li>
                            </ul>
                        : null}
                        {warnings && warnings.length > 0 && !(errors && errors.length) ?
                            <ul className='config-warning'>
                                <li>{warnings[0]}</li>
                            </ul>
                        : null}

                        {retryCount > 0 ? getHelp : null}
                    </div>

                    <footer>
                        <Link to="/preferences"><button className="close-modal-btn" onClick={this.closeModal}>
                            edit preferences
                        </button></Link>

                        <button className="retry-btn" onClick={this.retryPopulate}>Retry</button>
                    </footer>
                </div>
            );
        }

        return (
            <div className="profile-doctor preferences-form">
                <div className="scroll-container">
                    {retryCount == 0
                        ? <p>We were not able to build a meal plan that fits your profile.</p>
                        : <p>We were still not able to build a meal plan that fits your profile.</p>}

                    {retryCount > 0 ? getHelp : null}
                </div>

                <footer>
                    {(retryCount > 0) || (user.role === 'admin') ?
                        <button className="close-modal-btn" onClick={this.closeModal}>
                            close
                        </button>
                    : null}

                    <button className="retry-btn" onClick={this.retryPopulate}>Retry</button>
                </footer>
            </div>
        );
    }

    render = () => {
        const { drawer } = this.state;
        const { user } = this.context;

        if (!user) {
            return <span />
        }

        return (
            <Modal isOpen={true}
                closeModal={() => false}
                onRequestClose={() => false}
                className={drawer ? "profile-doctor-modal open" : "profile-doctor-modal"}
                overlayClassName="feed-modal-overlay"
                contentLabel="EatLove coachmark"
                closeTimeoutMS={250}>
                <div className="profile-doctor-modal-container">
                    {this.renderCulprit()}
                </div>
            </Modal>
        );
    }
}
