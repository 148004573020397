"use strict";

import { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import classNames from "classnames";
import moment from "moment";
import uuid from "uuid";
import debounce from "lodash.debounce";

import Button from "../Widgets/Button.react";
import LoadingWrapper from "../Widgets/LoadingWrapper.react.js";
import Calendar from "../Widgets/CalendarWidget.react";
import Select from "../../pro/components/Widgets/Select.react";
import AuthStore from "../../stores/AuthStore";
import UserStore from "../../stores/UserStore";
import reports from "../../tables/nutrition-exports";
import Analytics from "../../utils/Analytics";

import { getConfig } from "../../utils/Env";

import "./ExportModal.scss";


export default class ExportModal extends Component {
    static propTypes = {
        patient: PropTypes.object,
        openModalText: PropTypes.node,
    };

    static defaultProps = {
        openModalText: "Export Nutrition Analysis",
    };

    static contextTypes = {
        isPro: PropTypes.bool,
        isMobile: PropTypes.bool,
        isCordova: PropTypes.bool,
        showUpgradeForm: PropTypes.func,
        location: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            exportIsValid: false,
            validating: false,
            isModalOpen: false,
            isComplete: false,
            reportType: "Nutritional Analysis",
            errorMessage: null,
            exportDateRange: null,
            abortController: null,
            validateKey: null,
            delay: false,
            calendarInteractionCount: 0,
            lastResetTime: moment(),
            blockInteraction: false
        };

        this.debounceUpdateExportDateRange = debounce(this.updateExportDateRange, 500);
    }

    showModal = () => {
        const { showUpgradeForm } = this.context;
        const { export_nutrition = false } = UserStore.getCapabilities();
        const { patient } = this.props;
        const { isPro } = this.context;

        if (!export_nutrition) {
            return showUpgradeForm({ feature: "export_nutrition" });
        }

        Analytics.startExportNutrition(isPro && patient ? { "Patient UUID": patient.uuid } : {});

        this.setState({
            isModalOpen: true,
            isComplete: false,
            errorMessage: false,
            exportIsValid: false,
            exportDateRange: null,
        });
    };

    closeModal = () => {
        this.setState({
            isModalOpen: false,
            isComplete: false,
            errorMessage: false,
            exportIsValid: false,
            working: false,
        });
    };

    validateKey = null;

    updateExportDateRange = (dateRanges) => {
        const { reportType, abortController } = this.state;

        this.onCalendarInteraction();

        this.setState({ exportDateRange: dateRanges, exportIsValid: false });

        if (!dateRanges || !dateRanges[0]) {
            this.setState({ errorMessage: "" });
            return;
        }

        this.setState({ exportDateRange: dateRanges, validating: true }, async () => {
            try {
                if (abortController) {
                    abortController.abort("abortRequest");
                }

                let ac = null;
                let localKey = null;

                if ("AbortController" in window) {
                    ac = new AbortController();
                    this.setState({ abortController: ac });
                } else {
                    localKey = this.validateKey = uuid.v4().substring(0, 8);
                }

                const response = await AuthStore.fetch(
                    this.getRequestUrl(),
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json; schema=nutrition/export/1" },
                        body: JSON.stringify({
                            date_ranges: dateRanges
                                .filter(this.onlyValidRange)
                                .map((p) => p.map((q) => moment(q).format("YYYY-MM-DD"))),
                            report_type: reportType,
                            validate: true,
                        }),
                    },
                    false,
                    false,
                    ac,
                );

                if (localKey && localKey !== this.validateKey) {
                    return;
                }

                if (response.message == "") {
                    this.setState({ errorMessage: "No results.", exportIsValid: false, validating: false });
                } else {
                    this.setState({ errorMessage: false, exportIsValid: true, validating: false });
                }
            } catch (error) {
                if (error != "abortRequest") {
                    let errorMessage = (error && error.message) || "unknown error";

                    if (errorMessage == "Too many requests") {
                        errorMessage = `We’re sorry, you've exceeded the export rate limit. Please wait one minute before creating another export.`;
                    }

                    if (error.message === "No logged meals found for this date range") {
                        errorMessage =
                            "There are no logged meals within this date range. Please select a date range that contains logged meals.";
                    }

                    this.setState({ errorMessage, exportIsValid: false, validating: false });
                }
            }

            this.validateKey = null;
        });
    };

    createSlugFileName = (exportDateRange, reportType) => {
        const { patient } = this.props;

        const startDate = moment(exportDateRange[0][0]).format("YYYY-MM-DD");
        const endDate = exportDateRange[0][1]
            ? moment(exportDateRange[0][1]).format("YYYY-MM-DD")
            : moment(exportDateRange[0][0]).format("YYYY-MM-DD");
        const name = patient
            ? `${reportType} - ${patient["first_name"][0]} - ${patient["last_name"]} - `
            : `${reportType} - `;
        const dates = startDate != endDate ? `${startDate} to ${endDate}` : `${startDate}`;

        return name + dates + ".xlsx";
    };

    getErrorMessage = (error) => {
        if (error.message === "Too many requests") {
            return `We’re sorry, you've exceeded the export rate limit. Please wait one minute before creating another export.`;
        } else if (error.message === "No logged meals found for this date range") {
            return "There are no logged meals within this date range. Please select a date range that contains logged meals.";
        } else {
            return "We’re sorry, we are unable to complete the export. Our support team has been notified. Please email support@eatlove.is for assistance.";
        }
    };

    downloadNutritionReport = async (ev) => {
        const { exportIsValid, exportDateRange, reportType } = this.state;

        if (!exportIsValid) {
            ev.stopPropagation();
            ev.preventDefault();
            return;
        }

        this.setState({ working: true });

        try {
            const response = await AuthStore.fetch(this.getRequestUrl(), {
                method: "POST",
                headers: { "Content-Type": "application/json; schema=nutrition/export/1" },
                body: JSON.stringify({
                    date_ranges: exportDateRange
                        .filter(this.onlyValidRange)
                        .map((p) => p.map((q) => moment(q).format("YYYY-MM-DD"))),
                    report_type: reportType,
                }),
            });

            const timeoutId = setTimeout(() => {
                this.setState({ delay: true });
            }, 30000);

            let job = await AuthStore.fetch(getConfig("users_api") + "/jobs/" + response?.message);
            let governor = 500;

            while (job?.status !== "completed" && job?.status !== "failed" && governor-- > 0) {
                await new Promise((resolve) => setTimeout(resolve, 3000));
                job = await AuthStore.fetch(getConfig("users_api") + "/jobs/" + response?.message);
            }

            if (job?.status !== "completed" || !job?.output?.fileId) {
                throw Error("Failed to generate report");
            }

            const fileId = job?.output?.fileId;

            fetch("https://" + getConfig("self_host") + "/file-proxy/" + fileId, {
                method: "GET",
            }).then(async (response) => {
                if (!response.ok) {
                    throw await response.json();
                }

                const blob = await response.blob();

                if (blob) {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = this.createSlugFileName(exportDateRange, reportType);
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again

                    Analytics.downloadNutritionReport({
                        "Report Type": reportType,
                        "Date Ranges": exportDateRange,
                    });

                    clearTimeout(timeoutId);

                    this.setState({ isComplete: true, working: false, delay: false });
                }
            });
        } catch (error) {
            clearTimeout(timeoutId);

            Analytics.downloadNutritionError({
                Error: error.message || error.error || error.text,
                "Report Type": reportType,
                "Date Ranges": exportDateRange,
            });

            const errorMessage = this.getErrorMessage(error);

            this.setState({ exportIsValid: false, working: false, errorMessage: errorMessage, delay: false });
            return;
        }
    };

    emailNutritionExport = () => {
        const { exportIsValid, exportDateRange, reportType } = this.state;
        const { patient } = this.props;
        const { isPro } = this.context;

        if (!exportIsValid) {
            return;
        }

        this.setState({ working: true });

        AuthStore.fetch(this.getRequestUrl(), {
            method: "POST",
            headers: { "Content-Type": "application/json; schema=nutrition/export/1" },
            body: JSON.stringify({
                date_ranges: exportDateRange
                    .filter(this.onlyValidRange)
                    .map((p) => p.map((q) => moment(q).format("YYYY-MM-DD"))),
                report_type: reportType,
            }),
        })
            .then(async (response) => {
                if (!response.message) {
                    throw Error();
                }

                Analytics.emailNutritionReport({
                    "Report Type": reportType,
                    "Date Ranges": exportDateRange,
                    "Patient UUID": patient ? patient.uuid : null,
                    "Is Pro": isPro,
                });

                this.setState({ working: false, isComplete: true });
            })
            .catch((error) => {
                Analytics.emailNutritionError({
                    Error: error.message || error.error || error.text,
                    "Report Type": reportType,
                    "Date Ranges": exportDateRange,
                    "Patient UUID": patient ? patient.uuid : null,
                    "Is Pro": isPro,
                });

                const errorMessage = this.getErrorMessage(error);

                this.setState({ exportIsValid: false, working: false, errorMessage: errorMessage });
            });
    };

    onlyValidRange = (dateRange) => {
        return Array.isArray(dateRange);
    };

    getRequestUrl = (queryParams = {}) => {
        const { exportDateRange, reportType } = this.state;
        const { patient } = this.props;
        const { isPro } = this.context;

        if (patient?.links?.self?.href) {
            return [
                getConfig('users_api'),
                patient?.links?.self?.href,
                '/nutrition-export',
            ].join('');
        }

        return UserStore.getLinks().nutritionExport;
    };

    onChangeReportType = (value) => {
        const { exportDateRange } = this.state;

        if (exportDateRange) {
            this.setState({ reportType: value }, () => this.updateExportDateRange(exportDateRange));
        } else {
            this.setState({ reportType: value });
        }
    };

    onCalendarInteraction = () => {
        const { calendarInteractionCount, lastResetTime, blockInteraction } = this.state;

        if (blockInteraction) {
            return;
        }

        this.setState({calendarInteractionCount: calendarInteractionCount + 1});

        if (moment() - lastResetTime > 60000) {
            this.setState({calendarInteractionCount: 1, lastResetTime: moment(), blockInteraction: false});
        }

        if (calendarInteractionCount > 30) {
            this.setState({blockInteraction: true});

            setTimeout(() => {
                this.setState({blockInteraction: false, calendarInteractionCount: 25});
            }, 5000);
        }
    }

    renderModal = () => {
        const {
            isModalOpen,
            isComplete,
            exportDateRange,
            reportType,
            errorMessage,
            working,
            exportIsValid,
            validating,
            delay,
            blockInteraction
        } = this.state;
        const { patient } = this.props;
        const { isMobile, isCordova } = this.context;
        const notDesktop = isMobile || isCordova;
        const user = UserStore.getUser();

        if (!isModalOpen) {
            return;
        }

        const allTimeStartDate = patient ? patient.created : user.created;
        let toggleButtons = [];

        if (reportType == "Weight Log") {
            toggleButtons = [
                {
                    text: "This Month",
                    dateRange: [[moment().startOf("month").toDate(), moment().toDate()]],
                },
                {
                    text: "Last Month",
                    dateRange: [
                        [
                            moment().subtract(1, "months").startOf("month").toDate(),
                            moment().subtract(1, "months").endOf("month").toDate(),
                        ],
                    ],
                },
                {
                    text: "Last 3 Months",
                    dateRange: [[moment().subtract(3, "months").toDate(), moment().toDate()]],
                },
                {
                    text: "Last 6 Months",
                    dateRange: [[moment().subtract(6, "months").toDate(), moment().toDate()]],
                },
                {
                    text: "Year to date",
                    dateRange: [[moment().startOf("year"), moment().toDate()]],
                },
                {
                    text: "All time",
                    dateRange: [[moment(allTimeStartDate), moment().toDate()]],
                },
            ];
        }

        if (reportType == "Nutritional Analysis") {
            toggleButtons = [
                {
                    text: "Last 3 Days",
                    dateRange: [[moment().subtract(3, "days").toDate(), moment().subtract(1, "day").toDate()]],
                },
                {
                    text: "This Week",
                    dateRange: [[moment().startOf("week").toDate(), moment().toDate()]],
                },
                {
                    text: "Last Week",
                    dateRange: [
                        [
                            moment().subtract(1, "weeks").startOf("week").toDate(),
                            moment().subtract(1, "weeks").endOf("week").toDate(),
                        ],
                    ],
                },
                {
                    text: "This Month",
                    dateRange: [[moment().startOf("month").toDate(), moment().toDate()]],
                },
                {
                    text: "Last Month",
                    dateRange: [
                        [
                            moment().subtract(1, "months").startOf("month").toDate(),
                            moment().subtract(1, "months").endOf("month").toDate(),
                        ],
                    ],
                },
            ];
        }

        if (reportType == "Food Insights") {
            toggleButtons = [
                {
                    text: "Last 3 Days",
                    dateRange: [[moment().subtract(3, "days").toDate(), moment().subtract(1, "day").toDate()]],
                },
                {
                    text: "This Week",
                    dateRange: [[moment().startOf("week").toDate(), moment().toDate()]],
                },
                {
                    text: "Last Week",
                    dateRange: [
                        [
                            moment().subtract(1, "weeks").startOf("week").toDate(),
                            moment().subtract(1, "weeks").endOf("week").toDate(),
                        ],
                    ],
                },
                {
                    text: "This Month",
                    dateRange: [[moment().startOf("month").toDate(), moment().toDate()]],
                },
                {
                    text: "Last Month",
                    dateRange: [
                        [
                            moment().subtract(1, "months").startOf("month").toDate(),
                            moment().subtract(1, "months").endOf("month").toDate(),
                        ],
                    ],
                },
            ];
        }

        const reportOpts = reports.map((report) => {
            return { label: report.name, sub_label: report.description, value: report.name };
        });

        const containsDateRange = (mainRange, searchRange) => {
            const arraysHaveSameValues = (arr1 = [], arr2 = []) => {
                if (arr1.length !== arr2.length) {
                    return false;
                }

                const sortedArr1 = arr1.slice().sort();
                const sortedArr2 = arr2.slice().sort();

                for (let i = 0; i < sortedArr1.length; i++) {
                    if (!moment(sortedArr1[i]).isSame(sortedArr2[i], "day")) {
                        return false;
                    }
                }

                return true;
            };

            if (mainRange) {
                for (const array of mainRange) {
                    if (arraysHaveSameValues(array, searchRange)) {
                        return true;
                    }
                }
            }

            return false;
        };

        return (
            <Modal
                isOpen={true}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Nutrition Export"
                className="el-modal el-modal2 el-modal2-fixed export-modal"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal2-container">
                    <header>
                        {!isComplete ? <h2>Export Report In Progress</h2> : null}
                        {isComplete && notDesktop ? <h2>Check Your Inbox</h2> : null}
                        {isComplete && !notDesktop ? <h2>Report Exported</h2> : null}
                    </header>
                        {!isComplete && !working ? (
                            <div className="el-modal-body-container el-modal2-body-container el-form el-fonts">
                                <LoadingWrapper loading={blockInteraction}>
                                    <div className="export-select">
                                        <h6>Select a report</h6>
                                        <div>
                                            <div className="export-dropdown-wrapper">
                                                <Select
                                                    defaultClassName="el-select-container"
                                                    dropdownIcon={"icon-down-arrow"}
                                                    value={reportType}
                                                    options={reportOpts}
                                                    onChange={this.onChangeReportType}
                                                ></Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nutrition-export-form">
                                        <div className="export-toggle-btns">
                                            <h6>Select a date range</h6>

                                            {toggleButtons.map((btn, i) => {
                                                const isActive = containsDateRange(exportDateRange, btn.dateRange[0]);

                                                return (
                                                    <button
                                                        key={i}
                                                        className="el-flat-pill-toggle-btn"
                                                        data-active={isActive}
                                                        onClick={() => this.updateExportDateRange(btn.dateRange)}
                                                    >
                                                        {btn.text}
                                                    </button>
                                                );
                                            })}

                                            <div className="select-date-tip-left el-fonts p2">
                                                To select individual days, double-click on the day.
                                            </div>
                                        </div>

                                        <Calendar
                                            selectMultipleRange
                                            allowPartialRange
                                            withToolbar
                                            onChange={this.debounceUpdateExportDateRange}
                                            value={exportDateRange}
                                            tileDisabled={() => working}
                                            formatShortWeekday
                                            maxDate={new Date()}
                                        />

                                        <div className="select-date-tip-footer el-fonts p2">
                                            To select individual days, double-tap on the day.
                                        </div>
                                    </div>
                                </LoadingWrapper>
                            </div>
                        ) : null}

                        {isComplete && notDesktop ? (
                            <div className="el-modal-body-container el-modal2-body-container el-fonts">
                                <LoadingWrapper loading={blockInteraction}>
                                    <div className="nutrition-export-complete">
                                        <div>
                                            <i className="feather feather-mail" />
                                            <i className="feather feather-check el-small-circle-icon" />
                                        </div>

                                        <p className="p4">
                                            Your Nutrition Analysis Report will be sent to your email. It should arrive within
                                            the next few minutes, so please check your inbox.
                                        </p>
                                    </div>
                                </LoadingWrapper>
                            </div>
                        ) : null}

                        {isComplete && !notDesktop ? (
                            <div className="el-modal-body-container el-modal2-body-container el-fonts">
                                <LoadingWrapper loading={blockInteraction}>
                                    <div className="nutrition-export-complete">
                                        <div>
                                            <i className="feather feather-download" />
                                            <i className="feather feather-check el-small-circle-icon" />
                                        </div>

                                        <p className="p4">Check your downloads folder.</p>
                                        <p className="p2">A copy of your report has also been sent to your email.</p>
                                    </div>
                                </LoadingWrapper>
                            </div>
                        ) : null}

                        {working ? (
                            <div className="el-modal-body-container el-modal2-body-container el-fonts">
                                <LoadingWrapper loading={blockInteraction}>
                                    <div className="nutrition-export-complete">
                                        <div>
                                            <i className="icon-spinner" />
                                        </div>

                                        <p className="p4">
                                            {!notDesktop && delay
                                                ? `We need a little more time to export your report.`
                                                : `This can take up to 30 seconds.`}
                                        </p>
                                        {!notDesktop && delay && (
                                            <p className="p2">
                                                It will download to your computer and email inbox within minutes. Feel free to
                                                continue - no need to wait here.
                                            </p>
                                        )}
                                    </div>
                                </LoadingWrapper>
                            </div>
                        ) : null}

                    <footer className="modal-action-footer">
                        <p className="error-msg">{errorMessage && !exportIsValid && !blockInteraction ? errorMessage : null}</p>

                        <div className="footer-actions">
                            {!isComplete && !working ? (
                                <button onClick={this.closeModal} className="el-modal-cancel-btn">
                                    Close
                                </button>
                            ) : null}
                            {isComplete ? (
                                <button onClick={this.closeModal} className="el-modal-ok-btn">
                                    Continue
                                </button>
                            ) : null}

                            {!isComplete && delay ? (
                                <button onClick={this.closeModal} className="el-modal-ok-btn">
                                    Continue
                                </button>
                            ) : !isComplete ? (
                                <Button
                                    disabled={!exportIsValid}
                                    isLoading={validating || working || blockInteraction}
                                    onClick={notDesktop ? this.emailNutritionExport : this.downloadNutritionReport}
                                    className="el-modal-ok-btn el-btn-icon-left"
                                >
                                    Export Report
                                </Button>
                            ) : null}
                        </div>
                    </footer>
                </div>
            </Modal>
        );
    };

    render = () => {
        const { openModalText, className } = this.props;
        const { export_nutrition = false } = UserStore.getCapabilities();

        return (
            <span>
                <button className={classNames(className, "export-nutrition-btn")} onClick={this.showModal}>
                    {!export_nutrition ? <i className="icon-lock" /> : null}
                    <span>{openModalText}</span>
                </button>
                {this.renderModal()}
            </span>
        );
    };
}
