"use strict";

import { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DropTarget } from "react-dnd";
import MealDraggable from "./MealDraggable.react";
import MealTypes from "../../../constants/MealTypes";
import "./MealCell.scss";

@DropTarget(
    [MealTypes.MEAL],
    {
        drop(props, monitor, component) {
            var item = monitor.getItem();

            if (item.meals) {
                component.confirmDrop(item.meals);
            }
        },
    },
    (connect, monitor) => {
        return {
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
            connectDropTarget: connect.dropTarget(),
        };
    },
)
export default class MealCell extends Component {
    static propTypes = {
        date: PropTypes.object,
        mealType: PropTypes.string,
        meals: PropTypes.array,
        removeMeals: PropTypes.func,
    };

    static defaultProps = {
        height: 160,
    };

    static contextTypes = {
        user: PropTypes.object,
        isMobile: PropTypes.bool,
        startAddMeal: PropTypes.func,
        currentMealType: PropTypes.string,
        onModifyMeals: PropTypes.func,
        rescheduleMeals: PropTypes.func,
        confirm: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            mode: this.getMode(
                props.date,
                props.mealType,
                context.currentMealType,
            ),
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({
            mode: this.getMode(
                nextProps.date,
                nextProps.mealType,
                nextContext.currentMealType,
            ),
        });
    };

    getMode = (date, mealType, currentMealType) => {
        const properOrder = ["Breakfast", "Snack", "Lunch", "Dinner"];
        const defaultMealIndex = properOrder.indexOf(currentMealType);

        let dayMode = "past";
        const now = moment();

        if (date.isSame(now, "day") && mealType == currentMealType) {
            dayMode = "current";
        } else if (
            date.isSame(now, "day") &&
            defaultMealIndex < properOrder.indexOf(mealType)
        ) {
            dayMode = "future";
        } else if (date.isAfter(now, "day")) {
            dayMode = "future";
        }

        return dayMode;
    };

    confirmDrop = (confirmMealsDrop) => {
        const { mealType, date } = this.props;
        const { rescheduleMeals } = this.context;
        const mealsToDrop = confirmMealsDrop.filter((meal) => !meal.deleted);

        rescheduleMeals(mealsToDrop, date, mealType);
    };

    renderAddButton = (meals) => {
        const { mode } = this.state;
        const { date, mealType } = this.props;
        const { startAddMeal } = this.context;

        if (mode === "current") {
            return (
                <>
                    <div className="no-meal-scheduled">
                        <h3>No meal scheduled</h3>
                        <footer className="current-meal">
                            <button
                                className="add-meal-btn"
                                key="add"
                                onClick={() => startAddMeal(date, mealType)}
                            >
                                plan meal
                            </button>
                            <button
                                className="log-meal-btn"
                                key="log"
                                onClick={() =>
                                    startAddMeal(date, mealType, {
                                        defaultMode: "browser",
                                        auto_log: true,
                                    })
                                }
                            >
                                log meal
                            </button>
                        </footer>
                    </div>
                </>
            );
        }

        if (mode === "past") {
            return (
                <div
                    className="log-meal"
                    onClick={() =>
                        startAddMeal(date, mealType, {
                            defaultMode: "browser",
                            auto_log: true,
                        })
                    }
                    key="add"
                    title="Add a recipe here"
                >
                    <h3>Log Your Meal</h3>
                    <footer>
                        <button className="log-meal-btn">Log your Meal</button>
                    </footer>
                </div>
            );
        }

        return (
            <div
                className="no-meal-scheduled"
                onClick={() => startAddMeal(date, mealType)}
                key="add"
                title="Add a recipe here"
            >
                <h3>No meal scheduled</h3>
                <footer>
                    <button className="add-meal-btn">add a meal</button>
                </footer>
            </div>
        );
    };

    render() {
        const { mode } = this.state;
        const { user } = this.context;
        const { inhibit_swap = false } = user || {};
        const { isOver, meals, date, mealType, connectDropTarget } = this.props;

        const edibleMeals = meals.filter((m) =>
            ["fresh", "leftover", "food"].includes(m?.meal_type),
        );

        return connectDropTarget(
            <div
                className="grid-meal-cell"
                data-meal-type={mealType}
                data-hover={isOver}
            >
                {meals.length > 0 ? (
                    <MealDraggable
                        mode={mode}
                        mealType={mealType}
                        date={date}
                        meals={meals}
                        hideIcons={inhibit_swap}
                        disableDnD={inhibit_swap}
                        canShowMealCardAlert
                    />
                ) : null}

                {edibleMeals.length == 0 ? this.renderAddButton(meals) : null}
            </div>,
        );
    }
}
