'use strict'

import { render } from 'react-dom'
import React from 'react'
import { Link } from 'react-router';

import './BestOfBest.scss';

export default function BestOfBest(props) {
    return (
        <div className="banner-best">
            <div className="inner-container">
                <header className="header-row">
                    <h2>The Best of The Best</h2>
                    <p>
                    We collaborate with trusted registered dietitians, chefs and food experts to bring you great tasting meal plans optimized for your health concerns.
                    </p>
                </header>
                <section className="content-best">
                    <div className="dietitian">
                        <div className="best-image" id="best1"></div>
                        <h3>Sarah Koszyk</h3>
                        <h4>MA, RDN</h4>
                        <h5>Owner of Family. Food. Fiesta.</h5>
                    </div>
                    <div className="dietitian">
                        <div className="best-image" id="best3"></div>
                        <h3>Jenna Corbin</h3>
                        <h4>MS, RD, CSSD, CISSN, CLT, PES/CES</h4>
                        <h5>Performance &amp; Sports Dietitian</h5>
                    </div>
                    <div className="dietitian">
                        <div className="best-image" id="best4"></div>
                        <h3>Sheryl Akagi Allen</h3>
                        <h4>RD, PhD</h4>
                        <h5>Food Choice Architecture Specialist, Guckenheimer</h5>
                    </div>
                    <div className="dietitian">
                        <div className="best-image" id="best5"></div>
                        <h3>Adiana Castro </h3>
                        <h4>MS, RDN, CDN, CLT</h4>
                        <h5>Bariatric Dietitian</h5>
                    </div>
                    <div className="dietitian">
                        <div className="best-image" id="best6"></div>
                        <h3>Alanna Cabrero</h3>
                        <h4>MS, RDN, CDN</h4>
                        <h5>Outpatient Gastrointestinal Dietitian</h5>
                    </div>
                    <div className="dietitian">
                        <div className="best-image" id="best7"></div>
                        <h3>Sahar Berjis</h3>
                        <h4> RD, MPH, CDN</h4>
                        <h5>Inner Health & Wellness</h5>
                        <h5></h5>
                    </div>
                    <div className="dietitian">
                        <div className="best-image" id="best8"></div>
                        <h3>Tracy Owens</h3>
                        <h4>MPH, RD, CSSD, LDN</h4>
                        <h5>Owner of Triangle Theory Therapy</h5>
                    </div>
                </section>
            </div>
        </div>
    );
}

