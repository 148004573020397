'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Popup from '../../Widgets/Popup.react';
import Alert from '../../Widgets/Alert/Alert.react';

export default class AdminMenu extends Component {
    static propTypes = {
        onRefreshMealFeed: PropTypes.func,
    };

    static contextTypes = {
        user: PropTypes.object,
        confirm: PropTypes.func,
        populating: PropTypes.bool,
    };

    closeDropdown = () => {
        const { onHideDropdown } = this.props;
        onHideDropdown && onHideDropdown();
    }

    onRefreshMealFeed = () => {
        const { confirm } = this.context;
        const { onRefreshMealFeed } = this.props;
        this.inhibitAnalysis = true;

        onRefreshMealFeed(true).then(
            success => {
                this.refs.dropdown.closeDropdown();
            },
            error => {
                confirm(
                    <Alert type="error">
                        <p>An exception was thrown while refreshing the meal feed:</p>
                        <p><em>{error}</em></p>
                    </Alert>,
                    accept => false,
                    reject => false,
                    {rejectText: ''}
                );
            }
        );
    }

    render = () => {
        const { populating } = this.context;
        const button = [<i key="icon" className="icon-toolkit1" />, <span key="text">Tools</span>];

        return (
            <>
                <Popup className={"feed-tools-dropdown el-toolbar-popup"} button={button} ref="dropdown">
                    <div>
                        <button className="el-medium-btn el-wide-btn el-grayish-blue-outline-btn"
                            onClick={this.onRefreshMealFeed}>
                            {populating ? <i className="icon-spinner2" /> : null}Regenerate Meal Feed
                        </button>
                    </div>
                </Popup>
            </>
        );
    }
}
