'use strict'

import AppDispatcher from '../dispatcher/AppDispatcher';
import FulfillmentProviderConstants from '../constants/FulfillmentProviderConstants';

const FulfillmentProviderActions = {
    load: (postalCode) => {
        AppDispatcher.handleViewAction({
            actionType: FulfillmentProviderConstants.FULFILLMENT_PROVIDER_LOAD,
            postalCode
        });
    },

    reload: (postalCode) => {
        AppDispatcher.handleViewAction({
            actionType: FulfillmentProviderConstants.FULFILLMENT_PROVIDER_RELOAD,
            postalCode
        });
    },

    hydrate: (providers) => {
        AppDispatcher.handleViewAction({
            actionType: FulfillmentProviderConstants.FULFILLMENT_PROVIDER_HYDRATE,
            providers
        });
    },
};

export default FulfillmentProviderActions;
