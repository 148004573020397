'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import ImgResized from '../ImgResized.react';

// import './MyDietitianInfo.scss';

export default class MyDietitianInfo extends Component {

    render() {
        const { user } = this.props;
        const { my_dietitian, practice } = user;

        let avatar = my_dietitian.gender === 'male' ? <i className="avatar icon-male3" /> : <i className="avatar icon-female3" />;

        if (my_dietitian.image) {
            avatar = <ImgResized className="avatar" src={my_dietitian.image} width={120} height={120} />
        } else if (my_dietitian.name) {
            const words = my_dietitian.name.split(' ');
            const firstInitial = (words[0] && words[0][0]) || ''; // first letter of first word
            const lastInitial = (words.length > 1 && words[words.length - 1] && words[words.length - 1][0]) || ''; // first letter of last word

            avatar = (firstInitial || lastInitial) ? <em className="avatar">{firstInitial}{lastInitial}</em> : avatar;
        }

        return (
            <div key="rd" className="my-dietitian-info">
                {my_dietitian.managing_mode === 'business' && practice.logo_for_dark_bg ?
                    <Link to="/nutrition" className="dietitian-landing">
                        <ImgResized className="avatar-logo" src={practice.logo_for_dark_bg} height={50} />
                    </Link>
                : null}

                {my_dietitian.managing_mode === 'business' && !practice.logo_for_dark_bg ?
                    <Link to="/nutrition" className="dietitian-landing">
                        <p className="avatar-logo-text">{practice.name}</p>
                    </Link>
                : null}

                {my_dietitian.managing_mode === 'individual' ?
                    <Link to="/nutrition" className="dietitian-landing">
                        {avatar}
                        <p><span>{my_dietitian.name}</span> <em>{my_dietitian.credentials}</em></p>
                        {user.practice_type === 'dietetics'
                            ? <i className="icon-prescription-rx" />
                            : <i className="icon-pencil-notebook" />}
                    </Link>
                : null}
            </div>
        );
    }
}
