import jwtDecode from "jwt-decode";
import AuthStore from '../stores/AuthStore';

export const decodedToken = (token) => {
    return jwtDecode(getToken());
};


const getToken = () => {
    return AuthStore.getToken();
}

export const isLoggedIn = () => {
    return getToken() !== undefined;
}