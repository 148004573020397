'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Helmet from 'react-helmet';
import debounce from 'lodash.debounce';
import $ from 'jquery'; // skip

import Footer from '../components/Widgets/footer/Footer.react';

import LoginForm from '../components/Widgets/LoginForm.react';

import UserActions from '../actions/UserActions';
import UserStore from '../stores/UserStore';

import styles from './MyAccount.scss';
import '../components/MyAccount/PreferencesForm.scss';

export default class MyAccount extends Component {

    static contextTypes = {
        router: PropTypes.object,
        isCordova: PropTypes.bool,
        breakpoint47em: PropTypes.bool,
    };

    static childContextTypes = {
        user: PropTypes.object,
    };

    constructor(props, context) {
        super(props);

        this.state = {
            user: UserStore.getUser(),
            mode: this.getMode(context.router),
        };
    }

    getChildContext = () => {
        return {
            user: this.state.user,
        };
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({
            mode: this.getMode(this.context.router)
        });
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    getMode(router) {
        if (router.isActive('/my-account/settings')) {
            return 'account';
        } else if (router.isActive('/my-account/household')) {
            return 'household';
        } else if (router.isActive('/my-account/notifications')) {
            return 'notifications';
        } else if (router.isActive('/my-account/membership')) {
            return 'membership';
        }

        return false;
    }

    onUserStoreChange = () => {
        this.setState({
            user: UserStore.getUser(),
            preferences: UserStore.getRawPreferences(),
        });
    }

    setMode = (mode) => {
        this.setState({mode});
    }

    render = () => {
        const { mode, user } = this.state;
        const { breakpoint47em, isMobile, isCordova } = this.context;
        const { children } = this.props;

        if (!user) {
            return (
                <div className="my-account-login">
                    <Helmet title="Please sign-in or register | EatLove" />
                    <LoginForm defaultSignUp={false} goToOnboarding={() => this.context.router.push('/new-account')} />
                    <Footer />
                </div>
            );
        }

        const shouldShowMembership = !isCordova && user.capabilities.purchase_membership;

        return (

            <div>
                <Helmet title="My Account | EatLove" />
                <div className="my-account preferences-form">
                    <div className="preferences-tabs">
                        <Link to={mode === 'account' ? '/my-account' : "/my-account/settings"}>
                            <button className={mode === 'account' ? "account-tab active-tab" : "account-tab"} data-tab="name" >
                                <div className="inner-container">
                                    <p>Account</p>
                                </div>
                            </button>
                        </Link>
                        <section className="preferences-expansion" data-status={mode === 'account' ? true : false}>
                            <div className="inner-container">
                                {mode === "account" ? children : null }
                            </div>
                        </section>

                        <Link to={mode === 'household' ? '/my-account' : "/my-account/household"}>
                            <button className={mode === 'household' ? "account-tab active-tab" : "account-tab"} data-tab="household">
                                <div className="inner-container">
                                    <p>Household</p>
                                </div>
                            </button>
                        </Link>
                        <section className="preferences-expansion" data-status={mode === 'household' ? true : false}>
                            <div className="inner-container">
                                { mode === "household" ? children : null }
                            </div>
                        </section>


                        <Link to={mode === 'notifications' ? '/my-account' : "/my-account/notifications"}>
                            <button className={mode === 'notifications' ? "account-tab active-tab" : "account-tab"} data-tab="notifications">
                                <div className="inner-container">
                                    <p>Notifications</p>
                                </div>
                            </button>
                        </Link>
                        <section className="preferences-expansion" data-status={mode === 'notifications' ? true : false}>
                            <div className="inner-container">
                                { mode === "notifications" ? children : null }
                            </div>
                        </section>

                        {shouldShowMembership ?
                            <Link to={mode === 'membership' ? '/my-account' : "/my-account/membership"}>
                                <button className={mode === 'membership' ? "account-tab active-tab" : "account-tab"} data-tab="membership">
                                    <div className="inner-container">
                                        <p>Membership</p>
                                    </div>
                                </button>
                            </Link>
                        : null}
                        {shouldShowMembership ?
                            <section className="preferences-expansion" data-status={mode === 'membership' ? true : false}>
                                <div className="inner-container">
                                    { mode === "membership" ? children : null }
                                </div>
                            </section>
                        : null}
                    </div>
                </div>
            </div>
        );
    }
}
