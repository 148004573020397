'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';
import moment from 'moment';

import MealDraggable from '../../Dashboard/Grid/MealDraggable.react';

import FoodUnitsSelector from '../../Foods/FoodUnitsSelector.react';
import ImgResized from '../../Widgets/ImgResized.react';
import Select from '../../../pro/components/Widgets/Select.react';

import './ConfirmShiftOverwriteModal.scss';

export default class ConfirmShiftOverwriteModal extends Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        startDate: PropTypes.object,
        daysToShift: PropTypes.number,

        onModifyMeals: PropTypes.func.isRequired
    };

    static defaultProps = {
    };

    constructor(props) {
        super(props);

        this.state = {
            mode: 'replace',
        };
    }

    onSaveReschedule = () => {
        const { meals, startDate, daysToShift, onModifyMeals, closeModal } = this.props;
        const { mode } = this.state;

        const mealsToShift = meals.filter(meal => startDate.isSameOrBefore(meal.date, 'day'));
        const mealsToDelete = [];

        mealsToShift.forEach(meal => {
            const newDate = moment(meal.date).add(daysToShift, 'day');

            // Is there any meals in that slot already, and we're replacing? Add them to the mealsToDelete array
            // if they're also not already there.
            if (mode === 'replace') {
                const conflictMeals = meals.filter(cm => mealsToShift.indexOf(cm) === -1 &&
                                                         cm.meal === meal.meal &&
                                                         newDate.isSame(cm.date, 'day'));

                conflictMeals.forEach(cm => {
                    if (mealsToDelete.indexOf(cm) === -1) {
                        mealsToDelete.push(cm);
                    }
                });
            }

            meal.date = newDate.format('YYYY-MM-DD');
        });

        onModifyMeals(mealsToShift, mealsToDelete);
        closeModal();
    }

    render() {
        const { startDate, daysToShift, closeModal } = this.props;
        const { mode } = this.state;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Overwrite with leftovers"
                className="log-portions-modal"
                overlayClassName="feed-modal-overlay log-portions-overlay"
                closeTimeoutMS={250}>
                <div className="log-portions-modal-container leftover-offsets-container confirm-overwrite-container confirm-shift-overwrite-container">
                    <header>
                        <button className="close-modal-btn" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>

                        <h2>What should we do with your previously scheduled meals?</h2>
                    </header>

                    <section className="confirm-overwrite-form preferences-form">
                        <button className="checkbox-btn" data-selected={mode === 'replace'} onClick={() => this.setState({mode: 'replace'})}>
                            Replace
                        </button>

                        <button className="checkbox-btn" data-selected={mode === 'combine'} onClick={() => this.setState({mode: 'combine'})}>
                            Combine with currently scheduled
                        </button>
                    </section>

                    <footer>
                        <button className="cancel-btn" onClick={closeModal}>cancel</button>
                        <button className="ok-btn" onClick={this.onSaveReschedule}>Save</button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
