'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import moment from 'moment';
import indexBy from 'lodash.indexby';
import debounce from 'lodash.debounce';
import classNames from 'classnames';

import UserStore from '../../stores/UserStore';
import MealStore from '../../stores/MealStore';
import MealActions from '../../actions/MealActions';

import { computeWeeklyAnalysis } from '../../utils/Nutrition';
import { roundForHumans } from '../../utils/Math';

import './Goals.scss';

export default class Goals extends Component {

    static contextTypes = {
        user: PropTypes.object,

        meals: PropTypes.array,
        recipes: PropTypes.object,
        foods: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        const today = moment();
        let dayOfWeek = today.weekday() + 1;

        this.state = {
            // When weekStart and end are null, the module displays the current weeks analysis
            weekStart: moment().weekday(0),
            weekEnd: moment().weekday(6),

            meals: [],
            analysis: null,

            loading: false,
            synced: false,

            mealsLoggedTier: 'low',
        };

        this.syncAnalysis = debounce(this.syncAnalysis, 150);
    }

    componentDidMount = () => {
        MealStore.addChangeListener(this.onMealStoreChange);
        this.syncAnalysis();
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.syncAnalysis();
    }

    onMealStoreChange = () => {
        const meals = MealStore.getMeals();
        this.setState({synced: false, meals}, this.syncAnalysis);
    }

    syncAnalysis = (props, context) => {
        let { weekStart, mealsLoggedTier } = this.state;
        const { meals, recipes, foods, user } = context || this.context;

        const today = moment();
        const todayMeals = meals.filter(meal => today.isSame(meal.date, 'day'))
                                .filter(meal => ['Breakfast', 'Lunch', 'Dinner', 'Snack'].includes(meal.meal))
                                .filter(meal => ['fresh', 'leftover', 'food'].includes(meal.meal_type));
        const loggedMealsToday = todayMeals.filter(meal => meal.logged_portion);

        const mealsToday = todayMeals.length;
        const mealsLoggedToday = loggedMealsToday.length;

        const analysis = computeWeeklyAnalysis(weekStart || moment().weekday(0), user, meals, {...recipes, ...foods});

        if (analysis.logging.percentage <= 25) {
            mealsLoggedTier = 'low';
        } else if (analysis.logging.percentage <= 75) {
            mealsLoggedTier = 'medium';
        } else if (analysis.logging.percentage > 75) {
            mealsLoggedTier = 'high';
        }

        this.setState({mealsToday, mealsLoggedToday, mealsLoggedTier, analysis, synced: true});
    }

    onChangeWeekStart = async (weekStart) => {
        const { ensureDateRangeLoaded } = this.context;

        const weekEnd = moment(weekStart).add(6, 'day');

        this.setState({loading: true});

        try {
            this.setState({synced: false, weekStart, weekEnd});

            MealActions.ensureDateRangeLoaded(weekStart, weekEnd);

            this.syncAnalysis();
        } catch (exp) {
        }

        this.setState({loading: false});
    }

    onClickPrevWeek = () => {
        const { weekStart } = this.state;

        this.onChangeWeekStart(moment(weekStart).subtract(7, 'day'));
    }

    onClickNextWeek = () => {
        const { weekStart } = this.state;

        this.onChangeWeekStart(moment(weekStart).add(7, 'day'));
    }

    render() {
        const { weekStart, weekEnd, mealsToday, mealsLoggedToday, mealsLoggedTier, analysis, synced } = this.state;
        const { user } = this.context;

        const { hide_nutrition = false } = user || {};

        const now = moment();

        const isCurrentWeek = weekStart.isSame(now.weekday(0), 'day');
        let title = isCurrentWeek
                  ? 'Your Progress This Week'
                  : "Week of " + weekStart.format('M/D') + ' - ' + weekEnd.format('M/D');

        return (
            <section className="feed-goals" data-hide-nutrition={hide_nutrition}>
                <header>
                    <Link to="/log"><button className={classNames("el-medium-btn el-grayish-blue-outline-btn daily-log-btn", mealsToday - mealsLoggedToday > 0 ? "el-btn-badge-right" : '')}>
                        Daily Log
                        {mealsToday - mealsLoggedToday > 0 ?
                            <span className="el-btn-badge">{mealsToday - mealsLoggedToday}</span>
                        : null}
                    </button></Link>
                </header>
                <div className="goal-ctrls">
                    <button className="prev-week-btn" onClick={this.onClickPrevWeek}><i className="icon-chevron-left" /></button>
                    <h2>{title}</h2>
                    {!isCurrentWeek ?
                        <button className="next-week-btn" onClick={this.onClickNextWeek}><i className="icon-chevron-right" /></button>
                    : null}
                </div>
                <ul className="goals-summary-list">
                    {!hide_nutrition ? <li>
                        <div className="amount">
                            <div className="value">
                                <span className="numerator">{synced ? roundForHumans(analysis?.vegetables?.value) : '-'}</span>
                                <span className="demonimator">/{synced ? roundForHumans(analysis?.vegetables?.total) : '-'}</span>
                            </div>
                            <p>Veggie Servings</p>
                        </div>
                    </li> : null}
                    {!hide_nutrition ? <li>
                        <div className="amount">
                            <div className="value">
                                <span className="numerator">{synced ? analysis?.meals?.within_calories : '-'}</span>
                            </div>
                            <p>Meals Within Calorie Limits</p>
                        </div>
                    </li> : null}
                    <li>
                        <div className="amount">
                            <div className="value">
                                <span className="numerator">{synced ? analysis?.logging?.logged : '-'}</span>
                            </div>
                            <p>Log Entries</p>
                        </div>

                    </li>
                </ul>
            </section>
        );
    }
}
