'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';
import { IndexLink, Link } from 'react-router';

import Dropdown from '../../pro/components/Widgets/Dropdown.react';

import UserStore from '../../stores/UserStore';

import './Header.scss';

export default class Header extends Component {

    static contextTypes = {
        user: PropTypes.object,
        showLoginForm: PropTypes.func,
    };

    render = () => {
        const { showLoginForm } = this.context;

        const user = UserStore.getUser();

        return (
            <header className="home-page-header article-header">
                <section className="logo-container">
                    <Link to="/">
                        <img src="https://static.chewba.info/images/eatlove-logo-white-2019.svg" />
                        <span className="assistive-text">EatLove Home Page</span>
                        <div className="tagline">Find Health Through Food</div>
                    </Link>
                </section>

                <section className="menu-container">
                    <Link to="/pro"><button className="for-pros-btn">
                        {this.props.isMobile ? 'For Pros' : 'Professionals'}
                    </button></Link>

                    {!user ? <button onClick={() => showLoginForm()} className="sign-in-btn">Sign In</button> : null}
                    {!user ? <Link to="/new-account"><button className="sign-up-btn">Sign Up Free</button></Link> : null}
                    {user ? <Link to="/meals"><button className="sign-up-btn">Launch App</button></Link> : null}

                    <Dropdown className="mobile-submenu" button={<i className="icon-menu" />}>
                        <div>
                            {!user ? <button onClick={() => showLoginForm()} className="sign-in-btn">Sign In</button> : null}
                            {!user ? <Link to="/new-account"><button className="sign-up-btn">Sign Up Free</button></Link> : null}
                            {user ? <Link to="/meals"><button className="sign-up-btn">Launch App</button></Link> : null}
                        </div>
                    </Dropdown>
                </section>
            </header>
        );
    }
}


