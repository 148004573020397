'use strict';

import React, { useEffect, useState } from 'react';
import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';

import userProviderLogos from '../../tables/user-provider-logos';
import { getConfig } from '../../utils/Env';
import './ExternalData.scss';

const ExternalData = ({ provider, date, patient = false }) => {
    const [loading, setLoading] = useState(false);
    const [externalDatas, setExternalDatas] = useState();
    const [localDate, setLocalDate] = useState(date);

    const requestExternalData = () => {
        setLoading(true);

        const requestUrl = patient
            ? getPatientUrl(patient)
            : UserStore.getLinks().externalData;

        AuthStore.fetch(
            `${requestUrl}?provider=${provider}&date=${date.format('YYYY-MM-DD')}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json; schema=externalData/1',
                },
                body: null,
            }
        )
        .then((response) => {
            if (!response) {
                setExternalDatas([]);
                return;
            }
            
            setExternalDatas(response['elements']);
        })
        .catch((reason) => {
            setExternalDatas([]);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const getPatientUrl = (patient) => {
        return `${getConfig('users_api')}/practices/${
            UserStore.getUser().practice.uuid
        }/patients/${patient.uuid}/external-data`;
    };

    useEffect(() => {
        return requestExternalData();
    }, []);

    useEffect(() => {
        if (!localDate.isSame(date)) {
            setLocalDate(date);
            return requestExternalData();
        }
    }, [date]);

    return (
        <>
            {!loading && externalDatas?.length ? (
                <div className="activities-tracker-container el-fonts">
                    {externalDatas.map(({ activity, index }) => {
                        return (
                            <div
                                key={index}
                                className="activity-tracker-item">
                                <header>
                                    <img
                                        className="provider-logo"
                                        src={userProviderLogos.fitbit}
                                    />
                                    <div className="provider-title">
                                        <h6>Fitbit Tracker</h6>
                                    </div>
                                </header>

                                <div className="activity-tracker-info">
                                    {activity && (activity.steps || activity.caloriesOut) ? (
                                        <>
                                            <div>
                                                <span>Steps </span>
                                                {activity.steps}
                                            </div>
                                            <div>
                                                <span>Calories Burned </span>
                                                {activity.caloriesOut}
                                            </div>
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </>
    );
};

export default ExternalData;
