'use strict'

import { render } from 'react-dom'
import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router';
import $ from 'jquery';

import ImgResized from '../Widgets/ImgResized.react';

import './Testimonials.scss';



const testimonials = [
    {
        subject: 'This is the tool you\'ve been looking for',
        name: 'Mooselock',
        copy: 'I wish EatLove had been around when I started my journey. For years I had wanted to eat better, but it was so hard to know what to cook or how much to eat. About seven months ago, a registered dietitian at my gym introduced me to EatLove. At first I was skeptical, but quickly found that it checks all the boxes.',
    },
    {
        subject: 'EatLove is a very sustainable nutrition plan',
        name: 'Shay',
        copy: 'Two and a half months into using EatLove and I am down just over 24 lbs and 4% body fat. Nutrition has played a huge part in the success this time around after a few previously failed attempts with workouts and exercise alone. I can honestly say I’m not starving myself and haven’t had any real cravings for junk food. I\'m finding that EatLove is a very sustainable nutrition plan for the long term.',
    },
    {
        subject: 'EatLove changed my life!!!',
        name: 'Julie',
        copy: 'I have failed at just about every single diet or nutrition program I’ve ever tried. EatLove made it so easy and simple! I got to eat my favorite foods just in a better way and portioned out. I didn’t have to eat anything I had to choke down. I could set my likes and dislikes, along with the time I had to prepare and cook. It was AMAZING!!  EatLove helped me complete 75 Hard and changed my life!!!',
    },
    {
        subject: 'Keeps me on track!',
        name: '2actify',
        copy: 'EatLove has transformed the way I eat. Before, my dietitian would provide meal plans but they weren’t personalized and difficult to follow.',
    },
    {
        subject: 'EatLove helps me Retain my clients',
        name: 'jessegxoxo',
        copy: 'I’ve been using EatLove with my training clients for the past year and it has really be an great asset in helping keep my clients focused on consciously eating healthy meals.',
    },
    {
        subject: 'Excellent app for meal planning',
        name: 'Ilene C, RDN',
        copy: 'As a registered dietitian nutritionist I am looking for the best ways to help my clients with meal planning. I’ve been using EatLove with my clients for the past few years and the new app is the best thing EatLove has introduced.',
    },
    {
        subject: 'SO helpful',
        name: '.anonymous.',
        copy: 'The meal plans are so easy to follow. I love that this app helps me eat appropriate portions and helps me get the right amount of nutrients my body needs. HIGHLY recommend.',
    },
    {
        subject: 'Flexible meal plans!',
        name: 'VegHead69',
        copy: 'I was so pleased with my meal plans! I requested a vegan plan and have been so impressed by the creative and delicious recipes that Eat Love prepared for me. This app made eating healthy, easy.',
    },
    {
        subject: 'Making Health Convenient',
        name: 'Liv2244!',
        copy: 'I love that I can order my groceries online, so they’re ready for me when I’m home from work!',
    },
    {
        subject: 'Registered Dietitian - Use With My Clients',
        name: 'Kps16',
        copy: 'I was a very early adopter of EatLove.Is (before the platform went live!), and I’ve used it with my clients since. From a practitioner perspective, I love the control I have over setting perfect parameters for my clients.',
    },
    {
        subject: 'All this is so easy to do on the app!',
        name: 'Anna42598',
        copy: 'There are so many recipes on EatLove and I really needed to find the right ones for the few allergies my kids have + the missing ingredients + a bunch of random ingredients in my pantry, All this is so easy to do on the app! Also, the aesthetic is so modern and clean,  love it!',
    },
    // {
    //    subject: 'This is the tool you’ve been looking for',
    //    name: 'MOOSELOCK',
    //    copy: 'EatLove has transformed the way I feed myself, and as a result my relationship with food has improved dramatically.',
    //},
    // {
    //     subject: 'Flexible Meal Plans',
    //     name:  'Stephanie S.',
    //     image: 'https://static.chewba.info/images/7c216309-61cd-4a30-92b5-5943c34ae2cb.jpg',
    //     copy:  'EatLove may have genuinely changed my life. I do NOT cook, but even I can manage these recipes. For $9/month, you get a grocery list and daily meals scheduled with an eye to your nutrition goals. Get the most bang for your buck (bye, moldy unused food!).',
    // },
    // {
    //     subject: 'Flexible Meal Plans',
    //     image: 'https://static.chewba.info/images/b9d72f8a-c9bb-4596-9d2f-0251a227d4da.jpg',
    //     copy:  'I love EatLove for the amazing meal plans, customized to meet my criteria, and the easy grocery list that I have on my phone app at the store! No more forgetting! No more excuses to eat junk!',
    //     name:  'Liz T.',
    // },
    // {
    //     subject: 'Flexible Meal Plans',
    //     image: 'https://static.chewba.info/images/b765105c-d6bd-4ab0-9c8a-149bf61a764a.jpg',
    //     copy:  'Finally there is an RD approved centralized platform that takes all the hassle out of creating meal plans for clients that are suitable for any cooking level and with consideration to any dietary preferences.',
    //     name:  'Anna Snyder, RDN, CD',
    // },
    // {
    //     subject: 'Flexible Meal Plans',
    //     image: 'https://static.chewba.info/images/0b219913-0c61-4ecf-8597-333b43a9e426.jpg',
    //     copy:  'EatLove is truly an all-purpose meal planning program. From the provider to the client, it is an incredibly user-friendly platform that addresses all dietary, culinary and and overall lifestyle needs!',
    //     name:  'Megan Wroe, MS, RD, CLEC',
    // },
    // {
    //     subject: 'Flexible Meal Plans',
    //     image: 'https://static.chewba.info/images/a9fb4535-4b40-4e46-aae2-d58595d46f9c.jpg',
    //     copy:  'EatLove is so easy to navigate and understand; I was able to construct high quality meal plans in a matter of minutes.',
    //     name:  'Jason Mousel, RD',
    // },
];

export default function Testimonials(props) {
    const scroller = useRef(null);

    const scrollLeft = () => {
        const $scroller = $(scroller.current);
        const innerWidth = $scroller.innerWidth();
        const scrollJump = innerWidth > 400 ? ($scroller.innerWidth() * .75) : 256;

        $scroller.animate({scrollLeft: scroller.current.scrollLeft - scrollJump}, 250);
    }

    const scrollRight = () => {
        const $scroller = $(scroller.current);
        const innerWidth = $scroller.innerWidth();
        const scrollJump = innerWidth > 400 ? ($scroller.innerWidth() * .75) : 256;

        $scroller.animate({scrollLeft: scroller.current.scrollLeft + scrollJump}, 250);
    }

    return (
        <div className="banner-testimonials">
            <header>
                <h2>See What People are Saying<br />About Our 5-star App</h2>

                <p className="rating">
                    <i className="icon-star-full" /><i className="icon-star-full" /><i className="icon-star-full" /><i className="icon-star-full" /><i className="icon-star-full" />
                </p>
            </header>

            <div className="scroller" ref={scroller}>
                {testimonials.map((testimonial, i) => (
                    <div className="card" key={i}>
                        <p className="subject">{testimonial.subject}</p>
                        <p className="rating">
                            <i className="icon-star-full" /><i className="icon-star-full" /><i className="icon-star-full" /><i className="icon-star-full" /><i className="icon-star-full" />
                        </p>
                        <p className="author">{testimonial.name}</p>
                        <p className="quote">{testimonial.copy}</p>
                    </div>
                ))}
            </div>

            <section className="scroll-btn">
                <button className="scroll-left-btn" onClick={scrollLeft}><i className="icon-chevron-left" /></button>
                <button className="scroll-right-btn" onClick={scrollRight}><i className="icon-chevron-right" /></button>
            </section>

            {props.children || <footer>
                <Link to="/new-account"><button>Get Started Free • No Credit Card</button></Link>
            </footer>}
        </div>
    );
}

