'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import CalendarWidget from '../../Widgets/CalendarWidget.react';
import Select from '../../../pro/components/Widgets/Select.react';

import { getMealTypes, getPrimaryMeal } from '../../../utils/Meals';

import './MealRescheduleModal.scss';

export default class MealRescheduleModal extends Component {
    static propTypes = {
        closeModal: PropTypes.func,
        meals: PropTypes.array,
        onModifyMeals: PropTypes.func,
    };

    static defaultProps = {
    };

    static contextTypes = {
        preferences: PropTypes.object,
        plans: PropTypes.array,
        meals: PropTypes.array,
        recipes: PropTypes.object,
        foods: PropTypes.object,
        confirm: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        const { meals } = props;
        const { preferences, recipes, foods, plans } = context;
        const { primary, titles } = getPrimaryMeal(meals, recipes, foods);
        const date = moment(primary.date);
        const mealTypes = getMealTypes(date, preferences, plans);
        const ogDate = date ? moment(date) : moment();
        const ogMealType = primary.meal;

        this.state = {
            mealType: primary.meal,
            date,
            titles,
            primary,
            mealTypes,
            mode: 'single',
            ogDate, // original "gansta" date
            ogMealType,
            endOffset: 7,
            shiftDays: 1,
        };
    }

    onChangeDate = (date) => {
        this.setState({date: moment(date), mode: 'single'});
    }

    onRescheduleMeals = () => {
        const { meals, rescheduleMeals, shiftMeals, closeModal } = this.props;
        const { date, mealType, mode, ogDate, ogMealType, shiftDays } = this.state;

        if (!date || !mealType) {
            this.setState({error: 'Please choose a date'});
            return;
        }

        if (mode === 'single') {
            if (ogDate.isSame(date, 'day') && ogMealType == mealType) {
                closeModal(true); // no changes
            } else {
                rescheduleMeals(meals, date, mealType);
            }
        }

        if (mode === 'shift') {
            shiftMeals(ogDate, shiftDays);
        }
    }

    showLater = () => {
        let { endOffset } = this.state;

        endOffset += 7;

        if (endOffset > 28) {
            endOffset = 28;
        }

        this.setState({endOffset});
    }

    selectMealTypeDate = (date, mealType) => {
        this.setState({date, mealType});
    }

    isMealTypeDateSelected = (date, mealType) => {
        return date.isSame(this.state.date, 'day') && this.state.mealType == mealType;
    }

    range(min, max) {
        let arr = [];

        for(var i = min; i < max; i++) {
            arr.push(i);
        }

        return arr;
    }

    render() {
        const { mode, error, date, shiftDays, ogDate, endOffset, titles, mealType, mealTypes } = this.state;
        const { multiSelect, closeModal } = this.props;
        const { meals: allMeals, recipes, foods } = this.context;

        const mealTypeOpts = mealTypes.map((mealType) => ({value: mealType.name, label: mealType.name}));

        const now = moment();

        const shiftOpts = [
            {value: -7, label: '-7 days'},
            {value: -6, label: '-6 days'},
            {value: -5, label: '-5 days'},
            {value: -4, label: '-4 days'},
            {value: -3, label: '-3 days'},
            {value: -2, label: '-2 days'},
            {value: -1, label: '-1 day'},
            {value:  1, label: '+1 day'},
            {value:  2, label: '+2 days'},
            {value:  3, label: '+3 days'},
            {value:  4, label: '+4 days'},
            {value:  5, label: '+5 days'},
            {value:  6, label: '+6 days'},
            {value:  7, label: '+7 days'},
        ].filter(opt => {
            if (opt.value > 0) {
                return true;
            }

            const newDate = moment(ogDate).add(opt.value, 'day');

            if (newDate.isBefore(now, 'day')) {
                return false;
            }

            return true;
        });

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="meals-reschedule-modal"
                contentLabel="Reschedule Meal"
                overlayClassName="feed-modal-overlay"
                closeTimeoutMS={250}>
                <div className="edit-meal-modal-container meals-reschedule-modal-container">
                    <header>
                        <button onClick={closeModal} className="close-btn">
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="modal-scroll-container preferences-form">
                        <header><h1>Reschedule Meal</h1></header>
                        <section className="move-single">
                            <button className="radio-btn" data-selected={mode === 'single'} onClick={() => this.setState({mode: 'single'})}>
                                Choose a new meal time for: <br/> <em>{titles.join(' + ')}</em>
                            </button>

                            <Select options={mealTypeOpts} value={mealType} onChange={mealType => this.setState({mealType, mode: 'single'})} />
                            <CalendarWidget value={date.toDate()} onChange={this.onChangeDate} />
                        </section>

                        <section className="move-shift">
                            <button className="radio-btn" data-selected={mode === 'shift'} onClick={() => this.setState({mode: 'shift'})}>
                                Shift all meals starting from: <br /><em>{ogDate.format('dddd, MMM Do')} {titles.join(' + ')}</em>
                            </button>

                            <Select showAbove={true} options={shiftOpts} value={shiftDays} onChange={shiftDays => this.setState({shiftDays, mode: 'shift'})} />
                        </section>
                    </div>

                    <footer>
                        {error ? <p className="error-msg">{error}</p> : null}
                        <button disabled={!date || !mealType} className="ok-btn" onClick={this.onRescheduleMeals}>Save</button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
