'use strict'

import React, { useEffect, useState, useRef } from 'react'
import clamp from 'lodash/clamp'
import { useSprings, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import debounce from 'lodash.debounce';
import classNames from 'classnames';

import './SpringCarousel.scss';

export default function SpringCarousel(props) {
    let [ index, setIndex ] = useState(0);
    const { cardWidth, children, className, onChangeCard, showAll, horizontalOffset } = props;

    const informParentOfUpdate = debounce(() => {
        onChangeCard && onChangeCard(index);
    }, 50);

    const [cards, setCard] = useSprings(children.length, i => {
        return {
            x: (i - index) * cardWidth,
            sc: 1,
            o: i == index ? 1 : 0,
            display: 'block',
            onRest: informParentOfUpdate,
        };
    });

    useEffect(() => {
        setCard(i => {
            const x = (i - index) * cardWidth;

            return { x, sc: 1, display: 'block', immediate: false }
        });
    }, [props.cardWidth]); // only fire this effect when the cardWidth changes

    const setCards = (index, down = false, xDelta = 0) => {
        setCard(i => {
            const x = (i - index) * cardWidth + (down ? xDelta : 0);
            const sc = down ? 1 - Math.abs(xDelta) / cardWidth / 5 : 1
            const o = i == index ? 1 : 0;

            return { x, sc, o, display: 'block', immediate: false }
        });
    };

    const bind = useDrag((dragArgs) => {
        let { xy: [x, y], last, initial: [xInitial, yInitial], down, direction: [xDir, yDir],
                distance, cancel } = dragArgs;

        // Compute our difference from where we started to where we are now, x dimension only.
        let xDelta = x - xInitial;

        if (props.isSafariMobile) {
            cancel();
            return;
        }

        if (last) {
            cancel(); // will this stop propagation of the click event? Seems to otherwise.
        }

        if (down && Math.abs(xDelta) > cardWidth / 4) {
            index = clamp(index + (xDir > 0 ? -1 : 1), 0, children.length - 1);

            setIndex(index);
            cancel(index);
        }

        setCards(index, down, xDelta);
    });

    const goToPage = (page) => {
        index = page;

        setCards(index);
        setIndex(index);
    }

    const prevPage = () => {
        if (index > 0) {
            index = index - 1;

            setCards(index);
            setIndex(index);
        }
    }

    const nextPage = () => {
        if (index < children.length - 1) {
            index = index + 1;

            setCards(index);
            setIndex(index);
        }
    }

    return (
        <div className={classNames(className, "spring-carousel")} {...bind()}>
            {cards.map(({ x, o, display, sc }, i) => (
                <animated.div className="spring-carousel-item" key={i} style={{ display, opacity: showAll ? 1 : o.interpolate(o => o), transform: x.interpolate(x => `translate3d(${horizontalOffset ? x + (i * horizontalOffset) : x}px,0,0)`) }}>
                    <animated.div className="spring-carousel-item-container" style={{ transform: sc.interpolate(s => `scale(${s})`)}}>
                        {children[i]}
                    </animated.div>
                </animated.div>
            ))}

            <footer>
                {props.showPageDots ? cards.map((card, i) => {
                    return i === index ? <i key={i} onClick={() => goToPage(i)} className="icon-radio-selected" /> : <i key={i} onClick={() => goToPage(i)} className="icon-radio-unselected" />;
                }) : null}

            </footer>

            {props.showNextPrev ?
                <div>
                    {index > 0 ?
                        <button className="prev-btn" onClick={prevPage}><i className="icon-chevron-left" /></button>
                    : null}
                    {index < children.length - 1 ?
                        <button className="next-btn" onClick={nextPage}><i className="icon-chevron-right" /></button>
                    : null}
                </div>
            : null}
        </div>
    );
}

