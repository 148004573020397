'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import { getNutrientsForMeals } from '../../../utils/Nutrition';

import './LoggingSummaryOverlay.scss';

export default class LoggingSummaryOverlay extends Component {

    static contextTypes = {
        user: PropTypes.object,
        rx: PropTypes.object,
        recipes: PropTypes.object,
        products: PropTypes.object,
        foods: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        const { user, recipes, foods } = context;
        const { meals } = props;

        const nutrients = getNutrientsForMeals(meals, {...recipes, ...foods}, (user || {}).portion);

        // start theta off somewhere random
        const thetaStart = 2 * Math.PI * Math.random();

        this.state = {
            thetaStart,

            nutrients,
            slices: this.getMacroPortionSlices(nutrients, thetaStart),
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const { user, recipes, foods } = nextContext;
        const { meals } = nextProps;

        const nutrients = getNutrientsForMeals(meals, {...recipes, ...foods}, (user || {}).portion);

        this.setState({nutrients, slices: this.getMacroPortionSlices(nutrients, this.state.thetaStart)});
    }

    getMacroPortionSlices = (nutrients, thetaStart) => {
        const slices = [];

        // First, cho pro & fat are in grams. Convert to calories.
        let cho = nutrients[205] * 4 || 0;
        let pro = nutrients[203] * 4 || 0;
        let fat = nutrients[204] * 9 || 0;

        const calories = cho + pro + fat;

        // radians per calorie
        const radsPerCalorie = Math.PI * 2 / calories;

        const macros = [
            {kcal: cho, color: '#a30063', label: 'Carbs',   key: 'cho', grams: Math.round(nutrients[205])},
            {kcal: pro, color: '#2cd296', label: 'Protein', key: 'pro', grams: Math.round(nutrients[203])},
            {kcal: fat, color: '#a8acbe', label: 'Fat',     key: 'fat', grams: Math.round(nutrients[204])},
        ];

        macros.forEach(macro => {
            let percent = calories > 0 ? Math.round(macro.kcal / calories * 100) : 0;

            slices.push({
                ...macro,

                // Percentage of the meal
                percent,
                calories,
            });
        });

        return slices;
    }

    render() {
        const { user, recipes, foods } = this.context;
        const { rx, meals, mismatch, onClick } = this.props;
        const { nutrients, slices } = this.state;

        const { hide_nutrition = false } = user || {};

        if (hide_nutrition) {
            return (
                <button className="image-overlay-content logging-summary-overlay safe-mode">
                    <i className="icon-check2" />
                    <p>meal logged</p>
                </button>
            );
        }

        const fruitsAndVeg = Math.round(nutrients['FRU'] + nutrients['VEG']) || 0;
        const calories = Math.round(nutrients[208]) || 0;

        let calPercent = '0';

        if (rx && rx.envelope && rx.envelope[208]) {
            const { min, max } = rx.envelope[208];

            calPercent = Math.round(calories / (max || min) * 100);

            if (calPercent > 100) {
                calPercent = 100;
            }
        }

        return (
            <button className="image-overlay-content logging-summary-overlay" onClick={onClick}>
                <div className="calories">
                    <div className="value">
                        <span className="numerator">{calories}</span>
                    </div>
                    <div className="small-progress-bar">
                        <div className="small-progress-bar-fill" style={{width: calPercent + '%'}}></div>
                    </div>
                    <p>Calories</p>
                </div>
                <ul className="macros-legend">
                    {slices.map(macro => (
                        <li key={macro.key}>
                            <span className="name">{macro.label}</span>
                            <span>{macro.percent || '0'}%</span>
                            <span>{macro.grams || '0'}g</span>
                        </li>
                    ))}
                </ul>
            </button>
        );
    }
}
