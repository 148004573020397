'use strict';

import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import $ from 'jquery';
import Modal from 'react-modal';
import Markdown from 'react-markdown';
import debounce from 'lodash.debounce';
import Popup from '../Widgets/Popup.react';
import FeedGoals from './Goals.react';
import MealActions from '../../actions/MealActions';
import PlanActions from '../../actions/PlanActions';
import UserStore from '../../stores/UserStore';
import { getNutrientsForMeals } from '../../utils/Nutrition';
import { isSingular } from '../../utils/Math';
import MealCell from './Grid/MealCell.react';
import '../FeedModals.scss';
import _ from 'lodash';
import './Grid.scss';
import NutritionInfoModal from '../Nutrition/Modals/NutritionInfoModal.react';
import Analytics from '../../utils/Analytics';
import { getStartTimeForMeal } from '../../utils/Meals';

const GRID_SIZE_PAST = 7;
const GRID_SIZE_FUTURE = 7;

/**
 * The "Grid"
 *
 * This module borrows visual components from the customizer, and wiring components from the scheduler.
 *
 */
export default class Grid extends Component {
    static propTypes = {};

    static defaultProps = {};

    static contextTypes = {
        meals: PropTypes.array,
        plans: PropTypes.array,
        recipes: PropTypes.object,
        foods: PropTypes.object,
        brands: PropTypes.object,
        groceries: PropTypes.array,
        onRemoveMeals: PropTypes.func,
        confirm: PropTypes.func,

        isMobile: PropTypes.bool,

        user: PropTypes.object,

        autopopulate: PropTypes.func,
        populating: PropTypes.bool,
        location: PropTypes.object.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        const { location } = this.context;

        const isDateValid = moment(location?.query?.date, 'YYYY-MM-DD', true).isValid();

        const activeDate = isDateValid ? moment(location?.query?.date) : moment();

        const startDate = moment(activeDate).subtract(GRID_SIZE_PAST, 'days'),
            endDate = moment(activeDate).add(GRID_SIZE_FUTURE, 'days');

        const meals = context.meals.filter(
            (meal) => startDate.isSameOrBefore(meal.date) && endDate.isSameOrAfter(meal.date)
        );

        this.state = {
            meals,
            startDate,
            endDate,
            activeDate,

            bgMode: 'present',
        };

        this.currentDay = createRef();

        this.onScroll = debounce(this.onScroll, 100);
    }

    scrollable = null;
    scrolledToInitialDate = false;

    componentDidMount = () => {
        if (!process.browser) {
            return;
        }

        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchend', this.touchEnd);
    };

    realizeScrollable = (scrollable) => {
        const { activeDate } = this.state;
        if (scrollable && !this.scrollable) {
            this.scrollable = scrollable;

            this.scrollToDate(activeDate, 0, false);
            scrollable.addEventListener('scroll', this.onScroll);
        }

        this.scrollable = scrollable;
    };

    onScroll = () => {
        let visible = false;

        if (typeof this.currentDay.current?.getBoundingClientRect !== 'function') {
            return this.setState({ currentDayVisible: visible });
        }

        const leftOffset = window.innerWidth <= 1070 ? 0 : (window.innerWidth - 1070) / 2;
        const rightLimit = window.innerWidth < 1000 ? window.innerWidth : 1000;
        const todayPos = this.currentDay.current.getBoundingClientRect().left - leftOffset;

        visible = todayPos > -125 && todayPos < rightLimit;

        this.setState({ currentDayVisible: visible });
    };

    scrollToDate = (date, percentOffset = 0, animate = false, callback) => {
        const { startDate, endDate } = this.state;

        if (!this.scrollable || !process.browser) {
            return;
        }

        const $scrollable = $(this.scrollable);
        const currentScrollLeft = $scrollable.scrollLeft(),
            currentScrollWidth = this.scrollable.scrollWidth,
            viewportWidth = $scrollable.innerWidth();

        const animateScroll = () => {
            const $date = $scrollable.find('[data-date=' + moment(date).format('YYYY-MM-DD') + ']');

            // safety guard
            if (!$date[0]) {
                return;
            }

            const scrollLeft =
                $scrollable.scrollLeft() +
                $date.offset().left +
                $date.outerWidth() * (percentOffset || 0) +
                -18 -
                $scrollable.offset().left;

            if (animate) {
                // First, stop any existing animation, then restart it again
                $scrollable.stop(true, true);

                $scrollable.animate({ scrollLeft }, 500, null, callback);
            } else {
                $scrollable.scrollLeft(scrollLeft);

                callback && callback();
            }
        };

        animateScroll();
    };

    getDates = () => {
        const { startDate, endDate } = this.state;

        let dates = [],
            curDate;

        for (curDate = moment(startDate); curDate.isSameOrBefore(endDate, 'day'); curDate.add(1, 'day')) {
            dates.push(moment(curDate));
        }

        return dates;
    };

    getDayName = (date) => {
        if (moment().isSame(date, 'day')) {
            return 'Today';
        }

        if (moment().add(1, 'days').isSame(date, 'day')) {
            return 'Tomorrow';
        }

        return moment(date).format('dddd');
    };

    showClearMealsConfirmation = (date, clearWeek) => {
        const { onRemoveMeals, meals, confirm } = this.context;

        const mealsToRemove = clearWeek
            ? meals.filter(
                  (meal) =>
                      moment(meal.date).isSameOrBefore(moment(date).add(6, 'day'), 'day') &&
                      moment(meal.date).isSameOrAfter(date, 'day')
              )
            : meals.filter((meal) => date.isSame(meal.date, 'day'));

        const todayDateString = `for ${this.getDayName(date)}, ${date.format('MMM Do')}`;
        const thisWeekDateString = `from ${this.getDayName(date)}, ${date.format('MMM Do')} to ${this.getDayName(moment(date).add(6, 'day'))}, ${moment(date).add(6, 'day').format('MMM Do')}`;

        confirm(
            '',
            (accept) => null,
            (reject) => {
                !clearWeek ? Analytics.clearThisDay({ Date: date }) : Analytics.clearNext7Days({ Date: date });
                onRemoveMeals(mealsToRemove);
            },
            {
                titleText: `Clear all meals scheduled ${clearWeek ? thisWeekDateString : todayDateString}?`,
                acceptText: 'No, Keep my meals',
                rejectText: 'Yes, Clear my meals',
            }
        );
    };

    generateFollowingWeek = async (date) => {
        const { autopopulate, populating } = this.context;

        const response = await autopopulate(date, { replace: true, force: true }, 0);

        if (response?.explain && UserStore.getUser()?.role === 'admin') {
            this.setState({ explain: response.explain });
        }
    };

    closeExplain = () => {
        this.setState({ isExplainOpen: false });
    };

    showExplain = () => {
        this.setState({ isExplainOpen: true });
    };

    renderExplainModal = () => {
        const { explain, isExplainOpen } = this.state;

        if (!isExplainOpen) {
            return;
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={this.closeExplain}
                closeModal={this.closeExplain}
                className="el-modal el-modal1"
                contentLabel="Show Explanation"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal1-container style-guide-modal">
                    <header>
                        <button className="el-modal-back-btn" onClick={this.closeExplain}>
                            <span>Back</span>
                        </button>

                        <h2>The LENA Explains</h2>
                    </header>

                    <div className="el-modal-body-container el-modal1-body-container el-fonts">
                        <Markdown escapeHtml={true} className="markdown explain-markdown" source={explain} />
                    </div>
                </div>
            </Modal>
        );
    };

    renderDayOffsetHeader = (date, timeMode) => {
        const { user, populating } = this.context;
        const { explain } = this.state;
        const { hide_nutrition = false, shopping_freq = 7 } = user || {};
        let className = "day-offset-header " + timeMode;

        let dateName = timeMode === 'present' ? 'Today - ' + date.format('ddd, MMM D') : date.format('ddd, MMM D');

        return (
            <div className={className}>
                <span
                    className="date"
                    onClick={hide_nutrition ? null : () => this.setState({ showNutritionDate: date })}
                >
                    {dateName}
                </span>
                {timeMode !== 'past' ? (
                    <Popup
                        className="show-more-btn"
                        positionClassName="el-popup-top-center"
                        button={<i className="icon-ellipsis1" />}
                    >
                        <button
                            className="el-medium-btn el-grayish-blue-outline-btn"
                            onClick={() => this.showClearMealsConfirmation(date, false)}
                        >
                            <span>Clear this day</span>
                        </button>
                        <button
                            className="el-medium-btn el-grayish-blue-outline-btn"
                            onClick={() => this.showClearMealsConfirmation(date, true)}
                        >
                            <span>Clear the next 7 days</span>
                        </button>

                        {user?.capabilities?.publish ? (
                            <button
                                className="el-medium-btn el-grayish-blue-outline-btn el-btn-icon-left"
                                onClick={() => this.generateFollowingWeek(date)}
                            >
                                {populating ? <i className="icon-spinner2" /> : null}
                                <span>
                                    Populate next {shopping_freq} {isSingular(shopping_freq) ? 'day' : 'days'}
                                </span>
                            </button>
                        ) : null}

                        {explain ? (
                            <button
                                className="el-medium-btn el-grayish-blue-outline-btn el-btn-icon-left"
                                onClick={() => this.setState({ isExplainOpen: true })}
                            >
                                Show Explanation
                            </button>
                        ) : null}
                    </Popup>
                ) : null}
                {!hide_nutrition ? (
                    <button
                        className="daily-stats-btn"
                        onClick={hide_nutrition ? null : () => this.setState({ showNutritionDate: date })}
                    >
                        <i className="icon-analyze" />
                    </button>
                ) : (
                    <button className="no-button" />
                )}
            </div>
        );
    };

    renderDate = (date, mealTypes, i) => {
        const { meals } = this.context;
        let timeMode = 'present';

        if (moment().isSame(date, 'day')) {
            timeMode = 'present';
        } else if (moment().isAfter(date, 'day')) {
            timeMode = 'past';
        } else {
            timeMode = 'future';
        }

        return (
            <div
                key={i}
                data-date={date.format('YYYY-MM-DD')}
                className="meals-column-cell"
                ref={timeMode === 'present' ? this.currentDay : null}
                data-present={timeMode === 'present'}
            >
                {this.renderDayOffsetHeader(date, timeMode)}

                {mealTypes.map(mealType => (
                    <div key={mealType.name} className="meal-cell-outer-container">
                        <MealCell date={date}
                            mealType={mealType.name}
                            meals={meals.filter(meal => meal.meal == mealType.name && date.isSame(meal.date, 'day'))}
                            removeMeals={this.removeMeals}
                            onModifyMeals={this.onModifyMeals}
                        />
                    </div>
                ))}
            </div>
        );
    };

    nextPage = () => {
        // Add 75% of inner width to scroll left
        const $scrollable = $(this.scrollable);
        const viewportWidth = $scrollable.innerWidth();
        const scrollWidth = this.scrollable.scrollWidth;
        let scrollLeft = $scrollable.scrollLeft() + Math.round(viewportWidth * 0.75);

        if (scrollLeft > scrollWidth - viewportWidth - 50) {
            this.addNextWeek();
        } else {
            $scrollable.animate({ scrollLeft }, 250);
        }
    };

    prevPage = () => {
        // subtract 75% of inner width from scroll left
        const $scrollable = $(this.scrollable);
        const viewportWidth = $scrollable.innerWidth();

        let scrollLeft = $scrollable.scrollLeft() - Math.round(viewportWidth * 0.75);

        if (scrollLeft < 0) {
            scrollLeft = 0;
        }

        if (scrollLeft < 50) {
            this.addPrevWeek();
        } else {
            $scrollable.animate({ scrollLeft }, 250);
        }
    };

    addNextWeek = () => {
        const { startDate, endDate } = this.state;

        const newEndDate = moment(endDate).add(1, 'week');

        MealActions.ensureDateRangeLoaded(startDate, newEndDate);
        PlanActions.ensureDateRangeLoaded(startDate, newEndDate);

        this.setState({endDate: newEndDate}, this.nextPage);
    }

    addPrevWeek = () => {
        const { startDate, endDate } = this.state;
        const newStartDate = moment(startDate).subtract(1, 'week');

        MealActions.ensureDateRangeLoaded(newStartDate, endDate);
        PlanActions.ensureDateRangeLoaded(newStartDate, endDate);

        // What's our scrollables current scroll width? We're going to add the difference between before and after
        // to scrollLeft so we appear not to move
        const currentScrollWidth = this.scrollable.scrollWidth,
            currentScrollLeft = this.scrollable.scrollLeft;

        this.setState({ startDate: newStartDate }, () => {
            const newScrollWidth = this.scrollable.scrollWidth,
                newScrollLeft = this.scrollable.scrollLeft;

            this.scrollable.scrollLeft = currentScrollLeft + (newScrollWidth - currentScrollWidth);

            this.prevPage();
        });
    };

    closeNutritionInfoModal = () => {
        this.setState({ showNutritionDate: null });
    };

    renderNutritionInfoModal = () => {
        const { showNutritionDate } = this.state;
        const { user, meals, recipes, foods } = this.context;

        if (!showNutritionDate) {
            return null;
        }

        const dailyMeals = meals.filter((meal) => showNutritionDate.isSame(meal.date, 'day'));
        const contents = { ...recipes, ...foods };
        const nutrients = getNutrientsForMeals(dailyMeals, contents, user.portion, null);
        const dateFormatted = showNutritionDate.format('ddd, MMM Do');

        return (
            <NutritionInfoModal
                title={`Daily Nutrition for ${dateFormatted}`}
                profile={{ ...user, portion: 1 }}
                nutrients={nutrients}
                onClose={this.closeNutritionInfoModal}
            />
        );
    };

    getMealTypeForDate = (date) =>
    { 
        const { plans, user: { preferences: { meal_types }  } } = this.context;
        const defaultMealType = meal_types;
        const plan = plans.find(plan => {
            return moment(date).isBetween(plan.date_start, plan.date_end, 'day', '[]')});
        if (plan && plan?.profile_key?.meal_types) {
            return plan?.profile_key?.meal_types;
        }
        else {
            return defaultMealType;
        }
    }

    getMappings = () =>
    {
        const dates = this.getDates();
        let mappings = [];
        let typeTracker = [];
        let index = -1;
        
        dates.forEach((date) => {
            const mealType = this.getMealTypeForDate(date);

            if (_.isEqual(mealType, typeTracker)) {
                // Initialize if it doesn't exist
                if (!mappings[index]) {
                    mappings[index] = { dates: [], meal_type: mealType };
                }
                mappings[index]['dates'].push(date);
            } else {
                typeTracker = mealType;
                index++;
                // Initialize the new index
                mappings[index] = { dates: [date], meal_type: mealType };
            }
        });
        
        return mappings;
    }

    render () {
        const { bgMode, currentDayVisible } = this.state;

        const mealGrid = this.getMappings();

        return (
            <div className="meal-feed-grid meal-feed-bg" data-bg-mode={bgMode}>
                <div className="grid-container">
                    <button className="prev-page-btn" onClick={this.prevPage}>
                        <i className="icon-chevron-left" />
                    </button>
                    <button className="next-page-btn" onClick={this.nextPage}>
                        <i className="icon-chevron-right" />
                    </button>

                    <div className="meals-viewport-outer">
                        <div className="meals-column-viewport" ref={this.realizeScrollable}>
                            <div className="meals-column-container inner-slider">
                                <div className="meals-column-cell last-column">
                                    <button
                                        className="grid-prev-week-btn"
                                        title="Load previous week"
                                        onClick={this.addPrevWeek}
                                    >
                                        <i className="icon-chevron-left-double" />
                                    </button>
                                </div>

                                {mealGrid.map((obj) => (
                                    <>
                                        <div className="meals-column-cell meal-types">
                                            <div className="meal-types-column">
                                                {obj.meal_type.map((mealType) => {
                                                    const startTime = getStartTimeForMeal(mealType.begin);
                                                    const endTime = getStartTimeForMeal(mealType.end);
                                                    return (
                                                        <div key={mealType.name} className="meal-types-row">
                                                            <div className="meal-details">
                                                                <span>{mealType.name}</span>
                                                                <span className="range">{startTime} - {endTime}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        {obj.dates.map((date, index) => this.renderDate(date, obj.meal_type, index))}
                                    </>
                                ))}
                                <div className="meals-column-cell last-column">
                                    <button
                                        className="grid-next-week-btn"
                                        title="Load following week"
                                        onClick={this.addNextWeek}
                                    >
                                        <i className="icon-chevron-right-double" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    data-invisible={currentDayVisible}
                    className="grid-today-btn el-small-btn el-wide-btn el-grayish-blue-outline-btn"
                    onClick={() =>
                        this.currentDay.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'center',
                        })
                    }
                >
                    GO TO CURRENT DAY
                </button>
                <FeedGoals />
                {this.renderNutritionInfoModal()}
                {this.renderExplainModal()}
            </div>
        );
    }
}
