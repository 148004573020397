'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import store from 'store';

import './ConditionsScroller.scss';
import '../../pro/components/Widgets/Scroller.scss';

const LOCALSTORAGE_KEY = 'onboarding-entries2';

export default class ConditionsScroller extends Component {

    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            scrollPosition: 0,
        };
    }

    scrollIsRealized = (scrollable) => {
        if (!scrollable) {
            return;
        }

        this.setState({scrollable});

        scrollable.addEventListener('scroll', this.handleScroll);
    }

    moveLeft = () => {
        const { scrollable } = this.state;

        if (!scrollable) {
            return;
        }

        let scrollLeft = $(scrollable).scrollLeft();
        const parentWidth = $(scrollable).parent().width();

        scrollLeft -= (parentWidth) || 250;

        if (scrollLeft < 0) {
            scrollLeft = 0;
        }

        $(scrollable).animate({scrollLeft}, 250);
    }

    moveRight = () => {
        const { scrollable } = this.state;

        if (!scrollable) {
            return;
        }

        let scrollLeft = $(scrollable).scrollLeft();
        const parentWidth = $(scrollable).parent().width() + 1;

        scrollLeft += (parentWidth) || 250; // 250 = pixels

        $(scrollable).animate({scrollLeft}, 250); // 250 = milliseconds
    }

    clickCondition = (condition) => {
        // Insert this condition directly into the onboarding entries storage document
        const memory = store.get(LOCALSTORAGE_KEY) || {};

        memory.conditions = [{name: condition.name}];

        store.set(LOCALSTORAGE_KEY, memory);

        this.context.router.push('/new-account');
    }

    renderCondition = (condition, i) => {
        return (
            <div key={i} className="card-container" onClick={() => this.clickCondition(condition)} title={condition.consumer_name || condition.name}>
                <div className="card-inner-container">
                    <i className={"icon-" + condition.icon} />
                    <span>{condition.consumer_name || condition.name}</span>
                </div>
            </div>
        );
    }

    handleScroll = () => {
        const { scrollable } = this.state;

        if (!scrollable) {
            return;
        }

        this.setState({scrollPosition: scrollable.scrollLeft});
    }

    render() {
        const { scrollPosition } = this.state;
        const { title, conditions } = this.props;

        if (conditions.length <= 0) {
            return <span />
        }

        return (
            <div className="pro-scroller hp-conditions-scroller">
                <div className="header">
                    <h2>{title}</h2>
                </div>

                <div className="scroller-main" data-scroll-position={scrollPosition}>
                    <button className="hp-scroll-left" onClick={this.moveLeft}>
                        <i className="icon-chevron-left" />
                    </button>
                    <button className="hp-scroll-right" onClick={this.moveRight}>
                        <i className="icon-chevron-right" />
                    </button>
                    <div className="scroller-viewport" ref={this.scrollIsRealized}>
                        <div className="inner-slider">
                            {conditions.map(this.renderCondition)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
