'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { getConfig } from '../utils/Env';
import AuthStore from '../stores/AuthStore';

import './ShortLinkRedirect.scss';

export default class ShortLinkRedirect extends Component {

    static propTypes = {
        location: PropTypes.object,
        router: PropTypes.object,
    };

    static contextTypes = {
        router: PropTypes.object,
        navigateToAppUrl: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount = async () => {
        const { location, params } = this.props;
        const { navigateToAppUrl, router } = this.context;
        const { shortcode } = params;

        // If we get impatient, give the user a way out
        setTimeout(() => this.setState({longLoading: true}), 1500);

        try {
            const shortLink = await AuthStore.fetch(getConfig('users_api') + '/short-links/' + shortcode, {}, true);
            const parsed = new URL(shortLink.url);

            if (parsed.host === getConfig('self_host')) {
                navigateToAppUrl(shortLink.url);
            } else {
                const res = window.open(shortLink.url, '_system');
                this.setState({destination: {...shortLink, parsed}});
            }
        } catch (exp) {
            router.push('/');
        }
    }

    render = () => {
        const { longLoading, destination } = this.state;

        return (
            <div className="redirect-page">
                <i className="icon-spinner2" />

                {destination ?
                    <div className="el-fancy-box el-fonts">
                        <h5>You are being redirected outside the EatLove App</h5>
                        <p className="p4">If you are not redirected after a short time, please <a href={destination.url} target="_blank">click here</a>.</p>

                        <footer>
                            <a href={destination.url} target="_blank">
                                <button className="el-medium-btn el-raspberry-btn">Proceed To {destination.parsed.hostname}</button>
                            </a>
                        </footer>
                    </div>
                : null}

                {!destination && longLoading ?
                    <div className="el-fancy-box el-fonts">
                        <h5>Redirecting...</h5>

                        <footer>
                            <Link href="/">
                                <button className="el-medium-btn el-gray-btn">Return to EatLove</button>
                            </Link>
                        </footer>
                    </div>
                : null}
            </div>
        );
    }
}
