'use strict'

import { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import $ from 'jquery';
import platform from 'platform';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import debounce from 'lodash.debounce';
import { Plugins } from '@capacitor/core';
import * as Sentry from '@sentry/react';

import LoginModal from './components/Planner/Modals/LoginModal.react';
import Header from './components/Widgets/header/Header.react';
import PageMeta from './components/Widgets/PageMeta.react';
import CookieConsentBanner from './components/Widgets/CookieConsentBanner.react';
import UpgradeMembershipModal from './components/MyAccount/UpgradeMembershipModal.react';
import ChunkLoadingOverlay from './components/Widgets/ChunkLoadingOverlay.react';

import ChunkLoadingStore from './stores/ChunkLoadingStore';
import AuthActions from './actions/AuthActions';
import UserStore from './stores/UserStore';
import { getConfig } from './utils/Env';
import Analytics from './utils/Analytics';
import SessionRecorder from './utils/SessionRecorder';
import { decodedToken, isLoggedIn } from './utils/Auth';

import "../sass/style.scss";
import './Root.scss';

if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#root');
}

@DragDropContext(HTML5Backend)
export default class Root extends Component {

    static contextTypes = {
        router: PropTypes.object,
    };

    static childContextTypes = {
        confirm: PropTypes.func,
        showLoginForm: PropTypes.func,
        showUpgradeForm: PropTypes.func,
        isMobile: PropTypes.bool,
        isCordova: PropTypes.bool,
        iPad: PropTypes.bool,
        iPhone: PropTypes.bool,
        isAndroid: PropTypes.bool,
        hasTouchScreen: PropTypes.bool,

        breakpoint47em: PropTypes.bool,
        viewportWidth: PropTypes.number,
        viewportHeight: PropTypes.number,
        location: PropTypes.object,
        isDisconnected: PropTypes.bool,

        navigateToAppUrl: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const user = UserStore.getUser();

        this.state = {
            isSideNavOpen: false,
            user,

            // Login form options
            loginIsOpen: false,
            loginFormOptions: {},

            // Confirmation dialog state
            confirmDialogVisible: false,
            confirmDialogTitle: '',
            confirmDialogContents: 'Are you sure?',
            confirmDialogAcceptText: 'Ok',
            confirmDialogAccept: null,
            confirmDialogRejectText: 'Cancel',
            confirmDialogReject: null,
            confirmDialogPosition: null,

            ...this.getPlannerToolbarPages(props.location, user),
            ...this.getDeviceParams(),

            upgradeForm: null,

            lastLocation: props.location.pathname,
            isDisconnected: false,
            isChunkLoading: ChunkLoadingStore.isChunkLoading(),
        }

        this.handleResize = debounce(this.handleResize, 100);

        if (SessionRecorder.shouldSendData(null, props.location)) {
            SessionRecorder.start(props.location);
        }
    }

    getChildContext = () => {
        const { breakpoint47em, isMobile, iPad, iPhone, isAndroid, isCordova, hasTouchScreen, viewportWidth, viewportHeight, isDisconnected} = this.state;
        const { location } = this.props;

        return {
            confirm: this.confirm,
            showLoginForm: this.showLoginForm,
            showUpgradeForm: this.showUpgradeForm,
            breakpoint47em,
            isMobile, isCordova, iPad, iPhone, isAndroid,
            hasTouchScreen,
            viewportWidth,
            viewportHeight,
            location,
            isDisconnected,

            navigateToAppUrl: this.navigateToAppUrl,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        try {
            Analytics.trackUncaughtException(error, errorInfo);
            Sentry.withScope((scope) => {
                scope.setExtras(errorInfo);
                Sentry.captureException(error);
            });
        } catch (exp) {
            // we threw an additional exception, ignore it, nothing we can do.
        }
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
        ChunkLoadingStore.addChangeListener(this.onChunkLoadingStoreChange);

        if (process.browser) {
            window.addEventListener('resize', this.handleResize);
            window.addEventListener('offline', () => this.setState({isDisconnected: true}));
            window.addEventListener('online', () => this.setState({isDisconnected: false}));
        }

        this.setupSmartAppBanner(this.state.user);
        this.setupUniversalLinks();
        this.hideSplashScreen();

        // Try to detect if we're in Safari Private mode. Safari private mode sets a 0 byte quota on localstorage
        // so we can detect that. We only want to run this when we're sure we're in Safari, because it's possible
        // that spiders won't work (but they don't need localstorage to work)
        try {
            if (process.browser && platform.name === 'Safari' && window.localStorage) {
                localStorage.test = 1
                delete localStorage.test;
            }
        } catch (e) {
            this.setState({safariPrivateIncompat: true});
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const user = UserStore.getUser();
        const { location } = nextProps;

        if (SessionRecorder.shouldSendData(null, location)) {
            SessionRecorder.start(location);
        }

        const { lastLocation } = this.state;

        const newState = this.getPlannerToolbarPages(nextProps.location, user);

        // If the page changes, close the login and confirm modals.
        if (lastLocation !== location.pathname) {
            newState.loginIsOpen = false;
            newState.confirmDialogVisible = false;
            newState.lastLocation = location.pathname;
            newState.upgradeForm = null;
        }

        this.setState(newState);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
        ChunkLoadingStore.removeChangeListener(this.onChunkLoadingStoreChange);

        if (process.browser) {
            window.removeEventListener('resize', this.handleResize);
        }
    }

    setupUniversalLinks = async () => {
        // Support Universal Links
        if (!(Plugins && Plugins.App))  {
            return;
        }

        let launchUrl = await Plugins.App.getLaunchUrl();

        if (launchUrl && launchUrl.url) {
            this.navigateToAppUrl(launchUrl.url);
        }

        Plugins.App.addListener('appUrlOpen', (openUrl) => {
            if (openUrl && openUrl.url) {
                this.navigateToAppUrl(openUrl.url);
            }
        });

        Plugins.App.addListener('appStateChange', (state) => {
        });
    }

    hideSplashScreen = async () => {
        // Hide Wrapper splash screen
        if (!(Plugins && Plugins.SplashScreen))  {
            return;
        }

        Plugins.SplashScreen.hide();
    }

    parseQueryString(search) {
        const query = {};

        if (!search) {
            return query;
        }

        (search[0] === '?' ? search.substr(1) : search).split('&').forEach(pair => {
            pair = pair.split('=');

            let key = decodeURIComponent(pair[0] || '');
            let value = decodeURIComponent(pair[1] || '');

            if (key) {
                query[key] = value;
            }
        })

        return query;
    }

    /**
     * If the iOS app is called from an external URL via the Universal Links feature,
     * navigate to the correct route
     *
     * @param  {[type]} url [description]
     * @return {[type]}     [description]
     */
    navigateToAppUrl = (url) => {
        const { router } = this.context;
        const { isCordova } = this.state;
        const parsed = new URL(url);

        // Are we navigating to somewhere on our current domain? Navigate using the router.
        if (parsed.host === getConfig('self_host')) {
            router.push({
                pathname: parsed.pathname,
                query: this.parseQueryString(parsed.search),
            });
        } else {
            // Or if we're navigating outside the app, use the system browser
            const res = window.open(url, '_system');
        }
    }

    setupSmartAppBanner = (user) => {
        if (user && user.my_dietitian) {
            // <meta name="apple-itunes-app" content="app-id={{ IOS_APP_ID | raw }}">
            const meta = document.createElement('meta');
            meta.name = "apple-itunes-app";
            meta.content = "app-id=" + getConfig('ios_app_id');
            document.getElementsByTagName('head')[0].appendChild(meta);
        }
    }

    onUserStoreChange = () => {
        const user = UserStore.getUser();

        const newState = {
            user,
            ...this.getPlannerToolbarPages(this.props.location, user),
        };

        if (user) {
            newState.loginIsOpen = false;
            newState.loginFormOptions = {};
        }

        if (user && !this.state.user) {
            this.setupSmartAppBanner();
        }

        this.setState(newState);
    }

    onChunkLoadingStoreChange = () => {
        console.log({f: 'onChunkLoadingStoreChange', isChunkLoading: ChunkLoadingStore.isChunkLoading()})

        this.setState({isChunkLoading: ChunkLoadingStore.isChunkLoading()});
    }

    getDeviceParams = () => {
        let viewportWidth = process.browser ? $(window).width() : 1024,
            viewportHeight = process.browser ? $(window).height() : 1024;

        // Are we on an iPad? We're always mobile on an iPad
        const ua = navigator.userAgent;
        const iPad = /iPad/i.test(ua), iPhone = /iPhone/i.test(ua);
        const breakpoint47em = viewportWidth > 751;
        const isMobile = viewportWidth < 751;
        const isCordova = window.cordova ? true : false;
        const isAndroid = platform.os.family === 'Android';
        const hasTouchScreen = window.matchMedia("(pointer: coarse)").matches;

        return { viewportWidth, viewportHeight, isCordova, isMobile, iPad, iPhone, isAndroid, breakpoint47em, hasTouchScreen };
    }

    handleResize = () => {
        if (!process.browser) {
            return;
        }

        this.setState(this.getDeviceParams());
    }

    showLoginForm = (loginFormOptions = {}) => {
        this.setState({loginIsOpen: true, loginFormOptions});
    }

    confirm = (contents, acceptCallback, rejectCallback, userOpts = {}) => {
        const opts = {
            acceptText: 'Ok',
            rejectText: 'Cancel',
            titleText: '',
            ...userOpts,
        };

        this.setState({
            confirmDialogVisible: true,
            confirmDialogTitle: opts.titleText,
            confirmDialogContents: contents,
            confirmDialogAcceptText: opts.acceptText,
            confirmDialogAccept: acceptCallback,
            confirmDialogRejectText: opts.rejectText,
            confirmDialogRejectDefaultClass: opts.rejectDefaultClass,
            confirmDialogReject: rejectCallback,
            confirmDialogPosition: opts.position,
        });
    }

    closeModal = () => {
        this.setState({
            confirmDialogVisible: false,
            upgradeForm: null,
            loginIsOpen: false,
        });
    }

    showUpgradeForm = (opts = {}) => {
        const { user } = this.state;
        const { feature } = opts;

        if (!user) {
            return this.showLoginForm();
        }

        this.setState({upgradeForm: {feature}});
    }

    getPlannerToolbarPages = (location, user) => {
        const splitPath    = location.pathname.split('/');
        const locationPath = splitPath.slice(0, 3).filter(v => v).join(' ') || 'home-page';

        //we need to find if something is a menu detail page
        let menuPage = splitPath.includes('menus');
        let customizerPage = splitPath.includes('customize');
        let mealPage = splitPath.includes('meals');
        //we need to find a recipe detail page
        let recipePages = splitPath.includes('recipes');
        let recipePage = recipePages && locationPath.length > 7;
        // pages that dont have multiple parameters to scour through
        let darkHeaderPages = ['home-page', 'how-it-works', 'recipe-submit']
        // menu detail page is trickier
        let menuDetailPage = locationPath !== 'menus' && menuPage && !customizerPage;
        // the accounts and settings pages
        let newAccount = splitPath.includes('new-account') || splitPath.includes('accept-invite');
        let homePage = splitPath.includes('home-page');
        let settingsPages = splitPath.includes('my-account');
        let preferencePages = splitPath.includes('preferences');
        let onboarding = splitPath.includes('new-account');
        let dietitians = splitPath.includes('dietitians');
        let dietitiansConfirmed = splitPath.includes('dietitians') && splitPath.includes('confirmed');
        let toolbarPages = false; // (user ? true : false) && !newAccount && !customizerPage && !dietitians && !homePage;

        let showSignUp = true, showSignIn = true;
        let showDietitians = true;

        if (mealPage) {
            showSignUp = false;
            showSignIn = false;
            showDietitians = false;
        }

        if (newAccount) {
            showSignIn = false;
            showSignUp = false;
            showDietitians = false;
        }

        // Hide search on the customizer during first run
        if (location.query.signup && customizerPage) {
            showSignUp = false;
            showSignIn = false;
            showDietitians = false;
        }

        if (onboarding) {
            showSignUp = false;
        }

        if (dietitians && !dietitiansConfirmed) {
            showSignUp = false;
        }

        if (dietitians) {
            showDietitians = false;
        }

        return {
            page: locationPath,
            toolbarPages,
            showSignUp,
            showSignIn,
            showDietitians
        };
    }

    confirmDialogReject = () => {
        const { confirmDialogReject } = this.state;

        this.closeModal();

        confirmDialogReject && confirmDialogReject();
    }

    confirmDialogAccept = () => {
        const { confirmDialogAccept } = this.state;

        this.closeModal();

        confirmDialogAccept && confirmDialogAccept();
    }

    logout = () => {
        AuthActions.deauthorize();
    }

    renderUpgradeForm = () => {
        const { upgradeForm } = this.state;


        if (!upgradeForm) {
            return;
        }

        return (
            <UpgradeMembershipModal {...upgradeForm} closeModal={this.closeModal} />
        );
    }

    renderConfirmDialog = () => {
        const { confirmDialogVisible, confirmDialogContents, confirmDialogTitle,
                confirmDialogAcceptText, confirmDialogRejectText,
                confirmDialogPosition, confirmDialogRejectDefaultClass } = this.state;

        const confirmDialogPositionClass = 'el-modal3-' + (confirmDialogPosition ?? 'centered');

        if (!confirmDialogVisible) {
            return null;
        }

        return (
            <Modal isOpen={true}
                onRequestClose={confirmDialogRejectText ? this.confirmDialogReject : this.confirmDialogAccept}
                closeModal={() => false}
                contentLabel="Confirmation"
                className={`el-modal el-modal3 ${confirmDialogPositionClass}`}
                overlayClassName={`el-modal-overlay ${confirmDialogPositionClass}`}
                closeTimeoutMS={250}>

                <section className={`el-modal-container el-modal3-container`}>
                    {confirmDialogTitle ?
                        <header><h2>{confirmDialogTitle}</h2></header>
                    : null}

                    <div className="el-modal-body-container el-modal3-body-container el-fonts el-confirmation">
                        {typeof confirmDialogContents === 'string' ?
                            <p className="p3">{confirmDialogContents}</p>
                        : null}
                        <div className="el-confirmation">
                            {confirmDialogContents && typeof confirmDialogContents !== 'string' ?
                                confirmDialogContents
                            : null}
                        </div>
                    </div>

                    <footer>
                        {confirmDialogRejectText ?
                            <button className={confirmDialogRejectDefaultClass || "el-modal-cancel-btn"} onClick={this.confirmDialogReject}>{confirmDialogRejectText}</button>
                        : null}

                        {confirmDialogAcceptText ?
                            <button className="el-modal-ok-btn" onClick={this.confirmDialogAccept}>{confirmDialogAcceptText}</button>
                        : null}
                    </footer>
                </section>
            </Modal>
        );
    }

    renderLoginModal = () => {
        const { loginIsOpen, loginFormOptions } = this.state;

        if (!loginIsOpen) {
            return null;
        }

        return (
            <LoginModal closeModal={loginFormOptions.inhibitCloseModal ? null : this.closeModal}
                loginFormOptions={loginFormOptions}
                offModalCta={loginFormOptions.offModalCta} />
        );
    }

    render = () => {
        const { page, toolbarPages, breakpoint47em, isCordova, user, isMobile, hasError, isChunkLoading,
                showSignIn, showSignUp, showDietitians, safariPrivateIncompat, isDisconnected } = this.state;
        const { location, routes, children } = this.props;

        if (hasError) {
            return (
                <div className="error-loading-eatlove">
                    <img src="https://static.chewba.info/images/logo-icon-2018-08.png" />
                    <p>We're very sorry, but EatLove has encountered an error. Please try again!</p>
                    <p>Our technicians have been notified and are working the issue!</p>
                    <p>Need help? Please email us at <a href="mailto:support@eatlove.is?subject=EatLove Crashed">support@eatlove.is</a></p>

                    <footer>
                        <button onClick={() => window.location.reload(true)}>reload</button>
                    </footer>
                </div>
            );
        }

        if (safariPrivateIncompat) {
            return (
                <div className="error-loading-eatlove">
                    <img src="https://static.chewba.info/images/logo-icon-2018-08.png" />
                    <p>We're very sorry, but EatLove is not compatible with Safari's Private browsing mode.</p>
                    <p>Please turn off private browsing mode or use Chrome incognito, or Firefox private window.</p>
                    <p>Need help? Please email us at <a href="mailto:support@eatlove.is?subject=Safari Private Mode">support@eatlove.is</a></p>
                </div>
            );
        }

        if (isCordova && user && user.capabilities && !user.capabilities.native_app) {
            return (
                <div className="error-loading-eatlove">
                    <img src="https://static.chewba.info/images/logo-icon-2018-08.png" />
                    <p>We're very sorry, but the EatLove mobile app is only available for clients working with professionals. You may still access EatLove through your browser.</p>
                    <p>Please email us at <a href="mailto:support@eatlove.is?subject=Need Referral">support@eatlove.is</a> for a referral to a nutrition professional or coach near you.</p>

                    <footer>
                        <button onClick={this.logout}>sign out</button>
                    </footer>
                </div>
            );
        }

        // Do we have a toolbar title?
        const { toolTitle, hideToolbar, hideBackButton, hideNavigation, flatPageHeader, hideHeaderButtons, loggedOutHideToolbar } = routes[routes.length - 1] || {};

        const parentClasses = isMobile && isLoggedIn() && decodedToken().embed === "vg" ?
        ["vg-webview", "parent-layout"] :
        ["parent-layout"];

        // if (isMobile && !isCordova && platform.name === 'Safari') {
        //     parentClasses.push("safari-scroll-fix");
        // }

        return (
            <div className={classNames(parentClasses)} data-page={page}>
                <PageMeta role="main" className="main-layout" data-toolbar={toolbarPages && !breakpoint47em}>
                    {!hideToolbar && (user || !loggedOutHideToolbar) ?
                        <Header ref="header"
                            hideBackButton={hideBackButton}
                            hideHeaderButtons={hideHeaderButtons}
                            hideNavigation={hideNavigation}
                            toolTitle={toolTitle}
                            showSignIn={showSignIn}
                            showSignUp={showSignUp}
                            showDietitians={showDietitians}
                            location={location}
                            isDisconnected={isDisconnected}
                            flatPageHeader={flatPageHeader} />
                    : null}
                    {children}
                </PageMeta>

                <CookieConsentBanner />

                {this.renderConfirmDialog()}
                {this.renderUpgradeForm()}
                {this.renderLoginModal()}
                {isChunkLoading && <ChunkLoadingOverlay />}
            </div>
        );
    }
}
