'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import Modal from 'react-modal';

import modalStyles from '../../../jsx-styles/modals';
import './SubscriptionRequired.scss';
import '../../../pro/components/Modals/Modals.scss';

export default class SubscriptionRequired extends Component {
    static contextTypes = {
        isCordova: PropTypes.bool,
        user: PropTypes.object,
    };

    static propTypes = {
        closeModal: PropTypes.func,
    };

    render() {
        const { closeModal } = this.props;
        const { user, isCordova } = this.context;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                style={modalStyles.confirmDialog}
                contentLabel="Membership Required"
                className="modal-confirmation">

                <div className="modal-main subscription-required-modal">
                    <header>
                        <h1>Account Expired</h1>
                    </header>

                    <div>

                        {isCordova ?
                            <div>
                                <p>We're very sorry, but you're account has expired.</p>
                                <p>Please contact your {user.practice_type === 'dietetics' ? 'dietitian' : 'personal trainer'} to proceed.</p>
                                <p>If you believe this to be in error, please email us at <a href="mailto:support@eatlove.is?subject=My account has expired">support@eatlove.is</a></p>
                            </div>
                        : null}

                        <p>Plan your week with premium meals from the best cookbook authors and nutrition analysis from registered dietitians.</p>

                        <footer>
                            <button className="reject" onClick={closeModal}>Close</button>
                            {!isCordova ?
                                <Link to="/my-account/membership">
                                    <button className="accept">Choose a Membership Plan</button>
                                </Link>
                            : null}
                        </footer>
                    </div>
                </div>
            </Modal>
        );
    }
}
