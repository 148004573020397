"use strict";

import "./LoadingWrapper.scss";

const LoadingWrapper = ({ loading, children }) => {
    return (
        <div className="loading-wrapper">
            {children}
            {loading && (
                <div className="loading-overlay">
                    <div className="el-modal-loading el-fonts">
                        <h4>Processing...</h4>
                        <i className="icon-spinner2" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoadingWrapper;
