import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import assign from 'object-assign';

import ChunkLoadingConstants from '../constants/ChunkLoadingConstants';

const CHANGE_EVENT = 'change';

let _store = {
    isChunkLoading: false,
};

var ChunkLoadingStore = assign({}, EventEmitter.prototype, {
    isChunkLoading: function(language = 'en') {
        return _store.isChunkLoading;
    },

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }
});

export default ChunkLoadingStore;

AppDispatcher.register((payload) => {
    let existing;

    const { actionType, isChunkLoading } = payload.action;

    switch (actionType) {
        case ChunkLoadingConstants.UPDATE_IS_CHUNK_LOADING:
            _store.isChunkLoading = isChunkLoading;

            ChunkLoadingStore.emitChange();
            break;
    }
});
