'use strict'

import { render } from 'react-dom'
import React from 'react'
import { Link } from 'react-router';

import Dropdown from '../Search/Dropdown.react';

import { getConfig } from '../../utils/Env';
import UserStore from '../../stores/UserStore';

import './Header.scss';

export default function Header(props) {
    const proUrl = 'https://' + getConfig('pro_host');
    const user = UserStore.getUser();

    return (
        <header className="home-page-header">
            <section className="logo-container">
                <Link to="/">
                    <img src="https://static.chewba.info/images/eatlove-logo-white-2019.svg" />
                    <span className="assistive-text">EatLove Home Page</span>
                    <div className="tagline">Find Health Through Food</div>
                </Link>
            </section>

            {!user ?
                (<section className="menu-container">
                    <Link to="/pro" target="lp"><button className="for-pros-btn">
                        {props.isMobile ? 'For Pros' : 'Professionals'}
                    </button></Link>
                    <button onClick={() => props.showLoginForm()} className="sign-in-btn">Sign In</button>
                    <Link to="/new-account"><button className="sign-up-btn">Sign Up Free</button></Link>

                    <Dropdown className="mobile-submenu" button={<i className="icon-menu" />}>
                        <div>
                            <Link to="/new-account"><button className="sign-up-btn">Sign Up Free</button></Link>
                            <button onClick={() => props.showLoginForm()} className="sign-in-btn">Sign In</button>
                        </div>
                    </Dropdown>
                </section>) :
                (<section className="menu-container">
                    <Link to="/pro" target="lp"><button className="for-pros-btn">
                        {props.isMobile ? 'For Pros' : 'Professionals'}
                    </button></Link>
                    {user.practice_type !== "consumer" ?
                        <a href={proUrl} className="sign-up-btn">Launch Pro</a>
                        : <Link to="/"> <button className="sign-up-btn">Launch App</button></Link>
                    }
                    <Dropdown className="mobile-submenu" button={<i className="icon-menu" />}>
                        <div>
                            {user.practice_type !== "consumer" ?
                                <a href={proUrl} className="sign-up-btn">Launch Pro</a>
                                : <Link to="/"> <button className="sign-up-btn">Launch App</button></Link>
                            }
                        </div>
                    </Dropdown>
                </section>)

            }


        </header>
    );
}

