'use strict';

import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import moment from 'moment';
import $ from 'jquery';
import LocalStorage from 'store';

import Dropdown from '../../../pro/components/Widgets/Dropdown.react';
import ImgResized from '../ImgResized.react';
import SmartFraction from '../../Widgets/SmartFraction.react';

import { getMealsInGroceries } from '../../../utils/Grocery';
import { getAssetsForMeals } from '../../../utils/Meals';
import { getStepsForMeals } from '../../../utils/Tasks';
import { toHuman } from '../../../utils/Duration';
import { smartCeil } from '../../../utils/Math';

import GroceryStore from '../../../stores/GroceryStore';
import MealStore from '../../../stores/MealStore';
import ChatStore from '../../../stores/ChatStore';
import UserStore from '../../../stores/UserStore';

import './HeaderDropdown.scss';
import './NotificationsDropdown.scss';

export default class NotificationsDropdown extends Component {
    static contextTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
    }

    constructor(props) {
        super(props);

        this.state = {
            status: ChatStore.getStatus(),
            unreads: ChatStore.getUnreads(),
            unreadAlertHidden: true,
        };

        this.unreadAlertRef = createRef();
    }

    inhibitNotification = true;

    componentDidMount = () => {
        ChatStore.addChangeListener(this.onChatStoreChange);
        ChatStore.addMessageListener(this.onChatMessage);
        this.syncUnreadMessages();

        this.inhibitNotification = true;
    }

    componentWillUnount = () => {
        ChatStore.removeChangeListener(this.onChatStoreChange);
        ChatStore.removeMessageListener(this.onChatMessage);
    }

    onChatStoreChange = () => {
        const unreads = ChatStore.getUnreads();
        const status = ChatStore.getStatus();

        if (status === 'online' && status !== this.state.status) {
            // We just came online. Inhibit notifications for 5 more seconds and then disable the inhibit flag.
            setTimeout(() => {
                this.inhibitNotification = false;
            }, 5000);
        } else if (status !== 'online') {
            this.inhibitNotification = true;
        }

        // Set the badge favicon or not.
        if (unreads.length > 0) {
            $('#favicon').attr('href', 'https://static.chewba.info/images/logo-icon-2019-badge.ico');
        } else {
            $('#favicon').attr('href', 'https://static.chewba.info/images/logo-icon-2019.ico');
        }

        this.setState({unreads, status});
    }

    isAppHidden = () => {
        // Set the name of the hidden property and the change event for visibility
        var hidden;

        if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
              hidden = 'hidden';
        } else if (typeof document.msHidden !== 'undefined') {
              hidden = 'msHidden';
        } else if (typeof document.webkitHidden !== 'undefined') {
              hidden = 'webkitHidden';
        }

        return (document && document[hidden]) ? true : false;
    }

    onChatMessage = () => {
        const { router, location } = this.context;
        const latest = ChatStore.getLatest();

        // If we're not on the messaging tab, fire a notification.
        if (!window.cordova &&
            Notification.permission === "granted" &&
            !location.pathname.includes('/messages')) {

            // If it's okay let's create a notification
            const notification = new Notification('New Message!', {
                id: latest.id,
                body: latest.messageText,
                icon: 'https://static.chewba.info/images/logo-icon-2019.png',
            });

            notification.onclick = () => {
                notification.close();

                router.push('/messages/' + latest.from);
            }
        }
        this.syncUnreadMessages();
    }

    hideUnreadMessages = () => {
        const user = UserStore.getUser();

        if (!user) {
            return;
        }

        const expires = moment().add(1, 'day').format();
        const key = `unread-messages-${user.uuid}`;

        LocalStorage.set(key, {expires});
    }

    syncUnreadMessages = () => {
        const latestUnreadMessageThread = ChatStore.getLatestUnread();

        if (latestUnreadMessageThread && this.areUnreadMessagesHidden()) {
            this.checkToDeleteUnreadToken(latestUnreadMessageThread);
        }

        const unreadAlertHidden = this.areUnreadMessagesHidden();

        this.setState({unreadAlertHidden});      
    }

    areUnreadMessagesHidden = () => {
        const user = UserStore.getUser();

        if (!user) {
            return;
        }

        const key = `unread-messages-${user.uuid}`;

        let { expires } = LocalStorage.get(key) || {};

        if (!expires || (expires && moment().isAfter(expires))) {
            LocalStorage.remove(key);

            return false;
        }

        return true;
    }

    checkToDeleteUnreadToken = (latestUnreadMessageThread) => {
        const user = UserStore.getUser();
        const { unreads } = this.state;

        if (!user) {
            return;
        }
        const key = `unread-messages-${user.uuid}`;

        let { expires } = LocalStorage.get(key) || {};

        if (!expires) {
            return;
        }

        const chat = ChatStore.getChatWithJid(latestUnreadMessageThread.with);

        const latestTimestamp = latestUnreadMessageThread.messages[latestUnreadMessageThread.messages.length - 1].timestamp;


        //Check that the there is at least one non-fake unread message
        if ((!chat.flags.fake || unreads.length > 1) && moment(latestTimestamp) > moment(expires).subtract(1, 'days')
        ) {
            LocalStorage.remove(key);
            this.unreadAlertRef.current.openDropdown();
        }

    }

    render = () => {
        const { unreads, unreadAlertHidden } = this.state;

        let unread = (unreads || []).reduce((cv, chat) => cv + chat.unread, 0);
        let total = unread > 9 ? '9+' : unread;

        const badge = total ? (<div className="badge">{total}</div>) : null;

        const latestUnreadMessageThread = ChatStore.getLatestUnread(true);

        const shouldDisplayAlert = !unreadAlertHidden && unreads.length > 0;

        return (
                <>
                <div className="header-dropdown-container">
                    <Link to="/messages">
                        <button className="notifications-btn"><span><i className="icon-messages" />{badge}</span></button>
                    </Link>
                </div>
                {shouldDisplayAlert ? 
                    <Dropdown className="el-dropdown-popup notifications-dropdown-container" isDefaultVisible={true} onHideDropdown={this.hideUnreadMessages} ref={this.unreadAlertRef}>
                        <div>
                            <h5>You have unread messages from:</h5>
                            <ul className="el-dropdown-popup-list">
                                {unreads.map((sender, i) =>
                                    <li key={i}>
                                        <Link to={`/messages/${sender.rosterItem.jid}`}>
                                            <button className="notifications-btn">
                                                <span>
                                                    <i className="icon-messages" />
                                                    <div className="badge">{sender.unread}</div>
                                                    {sender.rosterItem.nick + (sender.rosterItem.credentials ? `, ${sender.rosterItem.credentials}` : '')}
                                                </span>
                                            </button>
                                        </Link>
                                    </li>                                
                                )}
                            </ul>
                            <Link to={`/messages/${latestUnreadMessageThread ? latestUnreadMessageThread.with : ''}`}>
                                <button className="el-medium-btn el-raspberry-outline-btn message-center-btn">VIEW MESSAGES</button>
                            </Link>
                        </div>
                    </Dropdown>
                : null}
                </>
        )
    }
}
