'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import qs from 'qs';

import DateSelector from '../../DailyLog/DateSelector.react';

import Analytics from '../../../utils/Analytics';
import { getConfig } from '../../../utils/Env';
import AuthStore from '../../../stores/AuthStore';

import './GridPrint.scss';

export default class GridPrint extends Component {

    static contextTypes = {
        meals: PropTypes.array,
        mealsInGroceryList: PropTypes.array,
        isCordova: PropTypes.bool,
    }

    constructor(props, context) {
        super(props, context);

        const params = {};

        if (context.isCordova) {
            params.token = AuthStore.getToken();
        }

        this.state = {
            params,
            isModalOpen: false,
            startDate: moment(),
        };
    }

    onChangeStartDate = (startDate) => {
        this.setState({startDate: moment(startDate)});
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    clickPrint = () => {
        this.closeModal();

        Analytics.downloadGridPDF();
    }

    renderChooseNewDate = () => {
        const { isModalOpen, startDate, params } = this.state;

        if (!isModalOpen) {
            return null;
        }

        const dateFormatted = startDate.format('YYYY-MM-DD');

        const printGridUrl = `https://${getConfig('self_host')}/grid/${dateFormatted}/printable?` + qs.stringify(params);

        return (
            <Modal isOpen={true}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                className="el-modal el-modal3 el-modal3-fixed choose-date-range-modal"
                overlayClassName="el-modal-overlay"
                contentLabel="Print Meal Schedule"
                closeTimeoutMS={250}>

                <div className="el-modal-container el-modal3-container choose-date-range-container el-fonts">
                    <header>
                        <h2>Print Your 7-Day Schedule</h2>
                    </header>

                    <div className="el-modal-body-container el-modal3-body-container">
                        <p className="p3">Which day would you like to start your printed schedule?</p>

                        <div className="el-labeled-input">
                            <label>Start Date</label>
                            <DateSelector date={startDate}
                                className="date-selector-popover"
                                format="MM/DD/YYYY" time={false}
                                showAbove
                                onChange={this.onChangeStartDate} />
                        </div>
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={this.closeModal}>close</button>
                        <a href={printGridUrl} target="_blank">
                            <button onClick={this.clickPrint} className="el-modal-ok-btn">Print</button>
                        </a>
                    </footer>
                </div>
            </Modal>
        );
    }

    render = () => {
        return (
            <>
                <button className="print-grid-btn el-toolbar-btn" onClick={() => this.setState({isModalOpen: true})}>
                    <i className="icon-print3" /><span>Print</span>
                </button>
                {this.renderChooseNewDate()}
            </>
        );
    }
}
