'use strict';

import moment from 'moment';

import { toSeconds } from './Duration';

const preferredPrepDays = [0, 3]; // Sunday and Wednesday

export function getRawStepsForMeal(meal, recipes, details, foods) {
    let steps = [];
    let detail = details[meal.details_uuid], recipe = recipes[meal.recipe_uuid];

    if (!detail || !recipe) {
        return steps;
    }

    // Determine our meal date (+time). Breakfast is at 7am, lunch is at noon, dinner is at 6pm,
    // snack is at 5pm.
    let mealDate = moment(meal.date);

    switch(meal.meal) {
        case 'Breakfast':
            mealDate.add(7, 'hours');
            break;

        case 'Lunch':
            mealDate.add(12, 'hours');
            break;

        case 'Dinner':
            mealDate.add(18, 'hours');
            break;

        case 'Snack':
        default:
            mealDate.add(15, 'hours');
            break;
    }

    // Loop through every ingredient and analyze it for work that can be done ahead of time.
    detail.ingredients.forEach(group => {
        group.items.forEach(ingredient => {
            if (!ingredient.food) {
                return;
            }

            let earliest = 9999999, // earliest things can be prepped is 7 days in advance
                latest = 0;         // latest things can be prepped is right before they're needed

            if (ingredient.food.recipe) {
                let subrecipe = recipes[ingredient.food.recipe];
                let subdetails = details[ingredient.food.details];

                if (!subrecipe || !subdetails) {
                    return;
                }

                // Determine the foundational recipe's preparation time (min / max)
                // The first instruction group to have earliest/latest time will do.
                subdetails.preparation.forEach(group => {
                    let groupEarliest = group.max_prep_time ? toSeconds(group.max_prep_time) : 0,
                        groupLatest = group.min_prep_time ? toSeconds(group.min_prep_time) : 0;

                    if (groupEarliest < earliest) earliest = groupEarliest;
                    if (groupLatest   > latest)   latest   = groupLatest;
                });

                if (earliest === 999999) {
                    earliest = 0;
                }

                // Process subrecipes ingredients, don't forget to scale

                // Is there a preferred prep step day within the subrecipes earliest/latest time?
                // Then that's the date we're shooting for
                earliest = moment(mealDate).subtract(earliest, 'seconds');
                latest   = moment(mealDate).subtract(latest, 'seconds');

                // Constrain the time even further by evaluating the meals created time.
                // If a user plans for thursdays dinners on wednesday, don't put the prep steps
                // any sooner than wednesday because they'd be in the past.
                if (earliest.isBefore(meal.created, 'day')) {
                    earliest = moment(meal.created);
                }

                if (latest.isBefore(meal.created, 'day')) {
                    latest = moment(meal.created);
                }


                // Add making the foundational recipe as a prep step by itself
                steps.push({
                    type: 'foundational',
                    uuid: subrecipe.uuid,
                    name: subrecipe.title,
                    grams_needed: ingredient.grams * meal.scaling,
                    meal,
                    subrecipe,
                    subdetails,
                    earliest,
                    latest,
                });

            } else if (ingredient.food.uuid) {
                // Process individual ingredient, don't forget to scale

                // Do we have prep steps that can be done ahead of time? If there are multiple,
                // they all have to go together, and can only be done in the minimum constraint
                // between all of them.
                if (!ingredient.prep_step) {
                    return;
                }

                let food = foods[ingredient.food.uuid];

                if (!food) {
                    return;
                }

                // Split the prep steps by comma
                let preps = ingredient.prep_step.split(/,|and/).map(v => v.trim());

                // Convert the prep steps to the advanced prep versions
                preps = preps.map(prep => {
                    return food.advance_prep.filter(ap => ap.prep_step === prep)[0];
                });

                // Filter out the bad stuff, but if we have to remove some, we can't advanced prep
                let goodPreps = preps.filter(prep => {
                    if (!prep) {
                        return false;
                    }

                    // Only prep steps that have a defined earliest & latest can be done in advanced
                    if (!prep.earliest && !prep.latest) {
                        return false;
                    }

                    return true;
                });

                // If all the prep steps are not doable in advanced
                if (goodPreps.length != preps.length) {
                    return;
                }

                goodPreps.forEach(prep => {
                    if (prep.earliest < earliest) earliest = prep.earliest;
                    if (prep.latest   > latest)   latest   = prep.latest;
                });

                if (earliest === 999999) {
                    earliest = 0;
                }

                // Is there a preferred prep step day within the subrecipes earliest/latest time?
                // Then that's the date we're shooting for
                earliest = moment(mealDate).subtract(earliest, 'seconds');
                latest   = moment(mealDate).subtract(latest,   'seconds');

                // Constrain the time even further by evaluating the meals created time.
                // If a user plans for thursdays dinners on wednesday, don't put the prep steps
                // any sooner than wednesday because they'd be in the past.
                if (earliest.isBefore(meal.created, 'day')) {
                    earliest = moment(meal.created);
                }

                if (latest.isBefore(meal.created, 'day')) {
                    latest = moment(meal.created);
                }

                steps.push({
                    type: 'ingredient',
                    uuid: food.uuid,
                    name: food.pretty_name || food.name,
                    prep_steps: goodPreps,
                    prep_steps_name: goodPreps.map(p => p.prep_step).join(', '),
                    grams_needed: ingredient.grams * meal.scaling,
                    meal, ingredient, food,
                    earliest, latest,
                });
            }
        });
    });

    // Loop through all the instruction groups and see if they can and/or should be made ahead
    // of time.
    (detail.preparation || []).forEach((group, key) => {

        // If the instruction group does not have an earliest or latest, then it
        // has to be done at the same time as the recipe
        if (!group.max_prep_time && !group.min_prep_time) {
            return;
        }

        let details = {
            preparation: [group],
        };

        if (detail.ingredients[group.ingredients_index]) {
            details.ingredients = [detail.ingredients[group.ingredients_index]];
        } else {
            details.ingredients = detail.ingredients; // just copy all the ingredients over
        }

        let step = {
            uuid: meal.uuid + '-instructions-' + key, // Ensure this won't get aggregated with anything else
            type: 'instructions',
            name: group.title || recipe.title,
            scaling: meal.scaling,
            meal,
            details,
            recipe,
            earliest: moment(mealDate).subtract(toSeconds(group.max_prep_time), 'seconds'),
            latest: moment(mealDate).subtract(toSeconds(group.min_prep_time), 'seconds'),
        };

        steps.push(step);
    });

    return steps;
}

export function getStepsForMeals(meals, recipes, details, foods) {
    let steps = [];

    meals.forEach(meal => {
        const recipe = recipes[meal.recipe_uuid];

        if (!recipe) {
            return;
        }

        // Is this a fresh or leftover meal?
        // If it's leftover AND the recipe freezes well AND it wasn't just made yesterday, add a thaw leftover step.
        if (meal.meal_type === 'leftover' && recipe.tags.indexOf('Freezes Well') != -1) {
            let leftoverDate = moment(meal.date);
            let original = meals.filter(m => m.uuid == meal.parent_uuid)[0];

            if (original && leftoverDate.diff(original.date, 'day') >= 2) {
                steps.push({
                    type: 'thaw',
                    uuid: meal.uuid, // Not collapsable
                    meal,
                    date: leftoverDate.subtract(1, 'day'),
                });
            } else {
            }
        }

        // If its a fresh item, let's scour it for tasks that can be accomplished ahead of time.
        // Then, we'll need to figure out where in the schedule to place them.
        if (meal.meal_type === 'fresh') {
            steps = steps.concat(getRawStepsForMeal(meal, recipes, details, foods));
        }
    });

    // Now that we have a buttload of raw steps, let's look for steps that have a time on
    // one of our preferred prep days for each week of our total timespan.
    // We'll remove all of those and aggregate them separately from
    // advanced prep steps that fall outside of the preferred times.
    let collector = {};

    // Loop through all the prep steps and assign each step to preferred prep day (if possible)
    // If the steps time contains a preferred prep step day. If it contains
    // more than one preferred prep step, then pick the later one (so the food is
    // fresher).
    steps.forEach(step => {
        let date = moment(step.latest);

        while (date.isAfter(step.earliest) || date.isSame(step.earliest, 'day')) {
            if (preferredPrepDays.indexOf(date.weekday()) != -1) {
                // Found a preferred prep day for this task, assign it this date and break out of the while loop
                step.date = moment(date);
                break;
            }

            date.subtract(1, 'day');
        }

        // Do we have a date yet? If not, simple schedule the step on the latest date it can be done
        // that way users will get the freshest yields.
        if (!step.date) {
            step.date = step.latest;
        }

        // eliminate any step that occurs on the same day as the meal it goes to
        // these are assumed to be done at the time the meal is prepared
        if (step.date.isSame(step.meal.date, 'day')) {
            return;
        }

        // Group and aggregate by a key comprised of:
        // uuid (which will be either food.uuid or recipe.uuid)
        // step name (or 'prepare' in case of subrecipe)
        // date - normalized date, YYYY-MM-DD
        let key = [step.uuid, step.prep_steps_name || 'prepare', step.date.format('YYYY-MM-DD')].join('-');

        if (collector[key]) {
            collector[key].push(step);
        } else {
            collector[key] = [step];
        }
    });

    // Now we need to aggregate steps.
    steps = Object.keys(collector).map(key => {
        let steps = collector[key];

        let first = steps[0];

        let meals = [],
            grams_needed = 0;

        // We can remove earliest & latest times
        delete first.earliest;
        delete first.latest;

        steps.forEach(step => {
            meals.push(step.meal);
            grams_needed += step.grams_needed;
        });

        return {
            ...first,
            grams_needed,
            count: steps.length,
            meals,
        };
    });

    return steps;
}

