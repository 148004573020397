'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Dropdown from '../../pro/components/Widgets/Dropdown.react';
import PrefsDropdown from './Header/PrefsDropdown.react';
import GridPrint from './Header/GridPrint.react';
import classNames from 'classnames';
import FeedTools from './Header/FeedTools.react';

import Analytics from '../../utils/Analytics';

import './Header.scss';
import { Link } from 'react-router';
import { getMealTypes } from '../../utils/Meals';

export default class Header extends Component {
    static propTypes = {
        onToggleView: PropTypes.func,
        currentView: PropTypes.string,
    };

    static contextTypes = {
        router: PropTypes.object,
        plans: PropTypes.array,
        meals: PropTypes.array,

        user: PropTypes.object,
        preferences: PropTypes.object,
        family: PropTypes.array,
        startAddMeal: PropTypes.func,
        changeCurrentMealType: PropTypes.func,

        isMobile: PropTypes.bool,
        iPhone: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            printDate: moment(),
        };
    }

    openPrefsEditor = () => {
        const { router } = this.context;
        router.push('/preferences');
    }

    onClickMealTypeButton = (mealType) => {
        const { startAddMeal, changeCurrentMealType, meals } = this.context;

        const now = moment();
        const mealsThis = meals.filter(m => now.isSame(m.date, 'day') && m.meal == mealType);

        this.refs.setMealType && this.refs.setMealType.closeDropdown();

        changeCurrentMealType(mealType);
        Analytics.changeCurrentMealType({'Meal Type': mealType});

        // If there are no meals scheduled for today, then we add them.
        if (!mealsThis.length) {
            startAddMeal(now, mealType);
        }
    }

    getStartTimeForMeal = (startTimeInMinutes) => {
        const midnightTime = moment('00:00', 'HH:mm');
        const currentTime = midnightTime.add(startTimeInMinutes, 'minutes');
        const formattedCurrentTime = (startTimeInMinutes % 60 === 0) ? currentTime.format('h a') : currentTime.format('h:mm a');
        return formattedCurrentTime;
    }

    render = () => {
        const { currentMealType, onToggleView, currentView, onRefreshMealFeed } = this.props;
        const { user, preferences, plans } = this.context;
        const now = moment();
        const mealTypes = getMealTypes(now, preferences, plans);

        const meals = {
            'Breakfast': {
                icon: <i className="icon-breakfast2" />,
                selected: <i className="icon-breakfast-white" />,
            },
            'Snack': {
                icon: <i className="icon-snacks3"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>,
                selected: <i className="icon-snack-white"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /><span className="path7" /><span className="path8" /><span className="path9" /><span className="path10" /></i>,
            },
            'Lunch': {
                icon: <i className="icon-lunch3"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /></i>,
                selected: <i className="icon-lunch-white"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /><span className="path7" /><span className="path8" /></i>,
            },
            'Dinner': {
                icon: <i className="icon-salad" />,
                selected: <i className="icon-dinner-white" />,
            },
        };

        let headerButtons = [
            <Dropdown ref="setMealType" defaultClassName="select-meal-type-dropdown" key="meal-type" className="el-toolbar-popup"
                button={[<i key="icon" className="icon-clock1" />, <span key="text">Up Next: <em>{currentMealType.toUpperCase()}</em></span>]}>
                <div className="select-meal-type">
                    <div className="select-meal-scrollable">
                        <p><em>{currentMealType} is your next scheduled meal.</em></p>
                        <p>To change your current meal time, select below.</p>
                        <section className="select-meal-table">
                            {mealTypes.map(type => {
                                return (
                                    <div key={type.name}>
                                        <button onClick={() => this.onClickMealTypeButton(type.name)}
                                             data-selected={currentMealType == type.name}>
                                            {currentMealType == type.name ? meals[type.main_dish].selected : meals[type.main_dish].icon} {type.name}<br />Time
                                            <br />{this.getStartTimeForMeal(type.begin)}
                                        </button>
                                    </div>
                                );
                            })}
                        </section>
                    </div>
                </div>
            </Dropdown>,
            <Link to="/log" key="log">
                <button className={classNames("el-toolbar-btn")}>
                    <i className="icon-pencil-notebook" /><span>Daily Log</span>
                </button>
            </Link>,
            <PrefsDropdown onClick={this.openPrefsEditor} onRefreshMealFeed={onRefreshMealFeed} key="prefs" />,
            <GridPrint key="print" />,
            user?.capabilities?.publish ? <FeedTools key="tools" onRefreshMealFeed={onRefreshMealFeed} /> : null,
            <button onClick={onToggleView} className="grid-toggle-btn el-toolbar-btn" key="grid-btn">
                <i className={currentView === 'feed' ? "icon-expand1" : "icon-collapse"} />
                <span>{currentView === 'feed' ? 'Expand' : 'Single Meal View'}</span>
            </button>
        ].filter(v => v);

        return (
            <div className="meal-feed-header sub-navigation" data-feed-mode={currentView}>
                <div className="dark-section" data-buttons={headerButtons.length}>
                    <div className="el-toolbar1">
                        {headerButtons}
                    </div>
                </div>
            </div>
        );
    }
}
