'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import PlanEditor from '../../pro/components/Plans/Editor.react';

import styles from './Customizer.scss';

export default class Customizer extends Component {
    render = () => {
        const { params, location } = this.props;

        return (
            <div className="customizer-page">
                <Link to="/">
                    <img className="eatlove-logo-header" src="https://static.chewba.info/images/header-logo-gray-green-2017-07-03.png" />
                </Link>
                <PlanEditor uuid={params.uuid}
                    location={location}
                    firstRun={location && location.query && location.query.signup ? true : false}
                    showAddToPlanner={true} />
            </div>
        );
    }
}
