'use strict'

import { render } from 'react-dom'
import React from 'react'

import './CpeAccredited.scss';

export default function CpeAccredited(props) {
    return (
        <div className="cpe-accredited">
            <div className="cpe-container">
                <img src="https://static.chewba.info/images/cdr-accredited-provider-2018.png" />

                <footer>
                    <h2>CPE Accredited Provider</h2>
                    <p>EatLove is proud to be recognized by the Commission of Dietetic Registration as one of CDR’s esteemed CPE Accredited providers.</p>
                </footer>
            </div>
        </div>
    );
}

