'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';

import './LoadingSplash.scss';

export default class LoadingSplash extends Component {

    static propTypes = {
        titleText: PropTypes.string,
    };

    static defaultProps = {
        titleText: 'Please wait while we design your personal menu.',
    };

    static contextTypes = {
        user: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            pithmark: 0, // this.pickAPithMark(context.user),
        };
    }

    interval = null

    componentDidMount = () => {
        this.interval = setInterval(() => {
            let pithmark = this.state.pithmark + 1;
            const allPithmarks = this.pickAPithMark(this.context.user);

            if (!allPithmarks[pithmark]) {
                return;
            }

            this.setState({pithmark});
        }, 3000);
    }

    componentWillUnmount = () => {
        if (this.interval) {
            clearTimeout(this.interval);
            this.interval = null;
        }
    }

    pickAPithMark(user, i) {
        const pithmarks = [
            (user && user.practice_type === 'dietetics'
                ? 'Matching meal recommendations to your nutrition prescription'
                : 'Matching meal recommendations to your nutrition requirements'),
            'Optimizing for minimal food waste and grocery efficiency',

            // user ? <span>Checking {user.name}&apos;s biometrics...</span> : null,
            // 'Evaluating biometrics and meal preferences',
            // ('Calculating ' + ((user && user.practice_type === 'dietetics') ? 'nutrition prescription' : 'nutrient profile')),
            // ('Matching meals to ' + ((user && user.practice_type === 'dietetics') ? 'prescription' : 'nutrient profile')),
            // 'Optimizing meal plan for minimal food waste and grocery efficiency',
        ].filter(v => v);

        return pithmarks[i] || pithmarks;
    }

    render() {
        const { isOpen, titleText, children, className } = this.props;
        const pithmark = this.pickAPithMark(this.context.user, this.state.pithmark);

        return (
            <Modal isOpen={isOpen}
                closeModal={() => false}
                contentLabel="Confirmation"
                className="loading-splash-screen-modal"
                overlayClassName="loading-splash-overlay loading-splash-wings"
                closeTimeoutMS={250}>

                {children ? <div className={classNames("loading-splash-screen-container", className)}>{children}</div> : null}

                {!children ? <div className={classNames("loading-splash-screen-container", className)}>
                    <h2>{titleText}</h2>

                    <div className="spinner"><i className="icon-spinner2" /></div>

                    <h3>{pithmark}</h3>
                </div> : null}

                <svg className="leaf" width="155px" height="177px" viewBox="0 0 155 177" version="1.1">
                    <defs>
                        <filter x="-12.4%" y="-10.7%" width="124.8%" height="121.3%" filterUnits="objectBoundingBox" id="filter-1">
                            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                            <feGaussianBlur stdDeviation="3.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.248075181 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                                <feMergeNode in="SourceGraphic"></feMergeNode>
                            </feMerge>
                        </filter>
                    </defs>
                    <g id="DESKTOP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="LOAD-SCREEN-MOBILE" transform="translate(-105.000000, -558.000000)" fill="#12D574" fillRule="nonzero">
                            <g id="o" filter="url(#filter-1)" transform="translate(111.831323, 562.113670)">
                                <path d="M101.63508,121.373737 C97.3063066,110.482877 104.042544,94.8782623 111.582934,77.8777296 C104.49873,82.2610482 94.3227829,102.675512 93.6135301,114.461581 C92.9825281,124.930969 93.0258158,135.238511 100.308144,144.025379 C102.923722,147.181369 105.429416,148.619434 108.344678,147.810206 C104.643424,151.201792 101.141133,154.809573 97.8557278,158.615087 C85.8283979,142.386692 59.3446302,133.940375 40.2247718,140.756435 C46.7429053,142.322629 52.3986141,143.593791 58.0043754,145.053773 C63.5518648,146.500268 69.0494068,148.137269 74.7950208,149.75404 C68.4267294,162.192549 51.3746927,167.066462 35.4930896,161.917749 C14.7782448,155.182611 -1.97577257,135.75608 0.188614046,119.731679 C3.12385529,120.824137 5.89759999,121.744634 8.58476922,122.875867 C19.3950479,127.427775 29.4794247,124.910738 38.5948222,118.748804 C53.3259705,108.791927 66.7135342,111.711892 75.5159281,127.417659 C76.619025,129.086776 77.8504408,130.665171 79.1987152,132.138156 L82.3870231,130.654572 C80.3891278,125.428307 79.889654,117.841794 76.1169616,115.383764 C54.7877639,101.444811 59.3779285,75.5326542 73.1900449,60.5164161 C78.3581535,55.1117264 83.8636952,50.0486899 89.6726815,45.3585632 C102.221129,34.8251114 106.957806,15.437711 108.344678,1.42108547e-14 L112.203946,5 C116.752488,10.8146407 121.509144,16.4758653 125.811278,22.4725823 C141.260004,44.0149074 144.220219,67.7606931 136.788048,92.8804805 C131.839928,109.638245 116.717525,121.45466 101.63508,121.373737 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </svg>

            </Modal>
        );

    }
}
