'use strict'

import { useState } from 'react';

import './ChunkLoadingOverlay.scss';

const ChunkLoadingOverlay = () => {
    let [isVisible, setIsVisible] = useState(false);
    let [isTakingForever, setIsTakingForever] = useState(false);

    setTimeout(() => setIsVisible(true), 500);
    setTimeout(() => setIsTakingForever(true), 10000);

    return (
        <div className="chunk-loading-overlay el-fonts" data-visible={isVisible}>
            {isTakingForever ? <h2>EatLove is loading...</h2> : null}

            {isTakingForever ? <p className="t3">EatLove is taking longer than usual to load.</p> : null}
            {isTakingForever ? <p className="t3">Please check your internet connection.</p> : null}

            {/*<i className="feather feather-cloud-off" />*/}
            <i className="icon-spinner2" />

            {isTakingForever ? <p className="t4">This message will disappear when connection is restored.</p> : null}
        </div>
    )
};

export default ChunkLoadingOverlay;
