'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import moment from 'moment';
import Dropdown from '../../../pro/components/Widgets/Dropdown.react';
import ImgResized from '../ImgResized.react';
import { getMealsInGroceries } from '../../../utils/Grocery';
import { getAssetsForMeals } from '../../../utils/Meals';

import GroceryStore from '../../../stores/GroceryStore';
import MealStore from '../../../stores/MealStore';

import './HeaderDropdown.scss';
import './GroceriesDropdown.scss';

export default class GroceriesDropdown extends Component {
    static contextTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
    }

    constructor(props) {
        super(props);

        const m = MealStore.getMeals();
        const g = GroceryStore.getGroceries();

        this.state = {
            meals: getMealsInGroceries(m, g),
            recipes: {},
            foods: {},
            recentlyAdded: null,
            inhibitRecentlyAdded: true,
            synced: false,
        };
    }

    componentDidMount = () => {
        GroceryStore.addChangeListener(this.onStoreChange);
        MealStore.addChangeListener(this.onStoreChange);

        this.syncAssets();
    }

    componentWillUnount = () => {
        GroceryStore.removeChangeListener(this.onStoreChange);
        MealStore.removeChangeListener(this.onStoreChange);
    }

    syncAssets = () => {
        const { meals } = this.state;

        getAssetsForMeals(meals).then(({recipes, foods}) => {
            this.setState({recipes, foods, synced: true});
        });
    }

    closeTimeout = null;

    onStoreChange = () => {
        let { inhibitRecentlyAdded } = this.state;
        const { location } = this.context;

        if (location.pathname != '/') {
            inhibitRecentlyAdded = true;
        }

        const m = MealStore.getMeals();
        const g = GroceryStore.getGroceries();
        const newMeals = getMealsInGroceries(m, g);
        const newState = {meals: newMeals};

        // have we added any meals?
        if (!inhibitRecentlyAdded && newMeals.length > this.state.meals.length) {
            newState.recentlyAdded = newMeals.filter(m1 => !this.state.meals.find(m2 => m2.uuid === m1.uuid));

            newState.synced = false;
        }

        // If we've loaded grocery store and meal store at least once, enable recently added functionality.
        if (inhibitRecentlyAdded &&
            GroceryStore.isLoaded() &&
            MealStore.isRangeLoaded(moment().subtract(1, 'day'), moment().add(1, 'day'))) {
            newState.inhibitRecentlyAdded = false;
        }

        this.setState(newState, () => {
            if (this.state.recentlyAdded) {
                this.refs.dropdown.openDropdown();

                if (this.closeTimeout) {
                    clearTimeout(this.closeTimeout);
                    this.closeTimeout = null;
                }

                this.closeTimeout = setTimeout(() => {
                    this.refs.dropdown.closeDropdown(500);
                    this.closeTimeout = null;
                }, 5000)
            }

            if (newState.inhibitRecentlyAdded === false || newState.synced === false) {
                this.syncAssets();
            }
        });
    }

    onHideDropdown = () => {
        this.setState({recentlyAdded: null});

        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
            this.closeTimeout = null;
        }
    }

    renderMeal = (meal, i) => {
        const { recipes, foods } = this.state;

        if (meal.recipe_uuid && recipes[meal.recipe_uuid]) {
            const recipe = recipes[meal.recipe_uuid];

            return (
                <li key={i}>
                    <ImgResized src={recipe.image} width={100} height={100} />
                    <p>{recipe.title}</p>
                </li>
            );
        }


        if (meal.food_uuid && foods[meal.food_uuid]) {
            const food = foods[meal.food_uuid];

            return (
                <li key={i}>
                    <ImgResized src={food.image} width={100} height={100} />
                    <p>{food.name}</p>
                </li>
            );
        }
    }

    navigateToGroceries = () => {
        const { router } = this.context;

        router.push({pathname: '/groceries'});
    }

    render = () => {
        const { meals, recentlyAdded } = this.state;

        // Do we show the recently added, or do we show the first couple of meals?

        const mealsToShow = recentlyAdded ? recentlyAdded : meals;

        let others = 0, snacks = 0, collector = {};

        meals.forEach(meal => {
            const key = meal.date + '-' + meal.meal;

            collector[key] = true;

            if (meal.meal === 'Snack') {
                snacks++;
            } else {
                others++;
            }
        });

        collector = Object.keys(collector);

        const counts = [
            (others ? (others + ' ' + (others == 1 ? 'MEAL' : 'MEALS')) : false),
            (snacks ? (snacks + ' ' + (snacks == 1 ? 'SNACK' : 'SNACKS')) : false),
        ].filter(v => v).join(', ');

        const badge = (collector.length > 0)
                    ? (<div className="badge" onClick={this.navigateToGroceries}>{collector.length > 9 ? '9+' : collector.length}</div>)
                    : null;

        return (
            <div className="header-dropdown-container groceries-dropdown-container">
                <Dropdown button={<span><i className="icon-cart3" />{badge}</span>} ref="dropdown"
                    defaultClassName="header-dropdown groceries-dropdown"
                    onHideDropdown={this.onHideDropdown}
                    onClick={this.navigateToGroceries}>
                    <Link to="/groceries"><div onClick={() => this.refs.dropdown.closeDropdown(500)}>
                        {recentlyAdded ?
                            <div className="recent">
                                {recentlyAdded.length} {recentlyAdded.length == 1 ? 'dish' : 'dishes'} added to groceries
                            </div>
                        : null}

                        {!recentlyAdded && counts ?
                            <div className="recent">
                                {counts}
                            </div>
                        : null}

                        <ul>
                            {mealsToShow.map(this.renderMeal)}
                        </ul>

                        <footer className="groceries-dropdown-footer">View Grocery List</footer>
                    </div></Link>
                </Dropdown>

            </div>
        );
    }
}
