'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import CollectionViewer from '../../components/Collections/Viewer.react';

import './Details.scss';

export default class CollectionDetails extends Component {
    render = () => {
        const { params, location } = this.props;

        return (
            <div className="collections-page">
                <CollectionViewer uuid={params.uuid} />
            </div>
        );
    }
}
