'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import uuid from 'uuid';
import Markdown from 'react-markdown';
import _ from 'lodash';
import { scrollTextFieldIntoView } from '../../utils/Keyboard';

import './Notes.scss';
import './EditDailyLog.scss';

export default class EditNotes extends Component {
    static propTypes = {
        meals:  PropTypes.array,
        mealType: PropTypes.string,
    };

    constructor(props) {
        super(props);

        const { meals, noteType } = props;

        const noteText = this.getTextFromMeals(meals, noteType);

        this.state = {
            noteText,
        };

    }

    componentDidUpdate(prevProps) {
        const { meals, noteType } = this.props;

        if (_.isEqual(prevProps.meals, meals) && prevProps.noteType === noteType) {
            return;
        }

        let sameDay = !meals.length && !prevProps.meals.length;
        const prevDate = prevProps.meals[0] && prevProps.meals[0].date;
        const nextDate = meals[0] && meals[0].date;

        if(prevDate && nextDate) {
            sameDay = moment(prevDate).isSame(moment(nextDate), 'day');
        }

        if (!sameDay) {
            this.setState({noteText: this.getTextFromMeals(meals, noteType)})
        }
    }


    openNoteEditorModal = () => {
        const { meals, noteType } = this.props;
        this.setState({isModalOpen: true, noteText: this.getTextFromMeals(meals, noteType)});
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    getTextFromMeals = (meals, property = 'notes') => {
        const notes = meals.map(meal => meal[property] || '').map(v => v.trim()).filter(v => v).join("\n");

        return notes;
    }

    saveNotes = () => {
        const { date, mealType, meals, onModifyMeals, noteType } = this.props;
        let { noteText } = this.state;

        noteText = (noteText || '').trim();

        // Are there no note meals? Create one.
        if (!meals.length) {

            let mealObject = {
                uuid: uuid.v4(),
                meal_type: 'note',
                meal: mealType,
                date: date.format('YYYY-MM-DD'),           
            }

            mealObject[noteType] = noteText;

            onModifyMeals([mealObject]);

            this.closeModal();
            return;
        }

        // If there is only one meal, great, let's update it and move along.
        const meal = meals[0];

        meal[noteType] = noteText;

        onModifyMeals([meal], false, false, meals.filter(m => m !== meal));
        this.closeModal();
        
    }

    onChangeNoteText = (ev) => {
        this.setState({noteText: ev.target.value});
    }


    renderModal = () => {
        const { user,  dateFormatted, title } = this.props;
        const { isModalOpen, noteText} = this.state;

        if (!isModalOpen) {
            return;
        }

        return (
            <Modal isOpen={true}
                className="edit-timings-modal"
                overlayClassName="feed-modal-overlay"
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Edit recipe timings"
                closeTimeoutMS={250}>
                <div className="edit-daily-log-modal-container">
                    <header className="modal-header">
                        <button className="close-btn" onClick={this.closeModal}>
                            <i className="icon-close-x" />
                        </button>
                    </header>
                    <div className="edit-daily-log-modal-body daily-log-extras">
                        <h2>{title ? `${title} Notes`: "NOTES"}</h2>
                        <label className="date-label">{dateFormatted}</label>
                        <div className="daily-log-notes">
                            <textarea placeholder="Enter notes" type="text" value={noteText}
                                onChange={this.onChangeNoteText} onFocus={scrollTextFieldIntoView} />
                        </div>
                    </div>
                    <footer>
                        <button className="cancel-btn" onClick={this.closeModal}>cancel</button>
                        <button className="save-btn" onClick={this.saveNotes}>save</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    render() {
        let { meals, editing, noteType, isExtra, title } = this.props;
        // We want to get the value from props rather than state so that it will not persist on cancel
        const noteText = this.getTextFromMeals(meals, noteType);

        const className = noteText.length ? 'edit-notes non-empty-notes' : 'edit-notes empty-notes';
        const buttonClassName = noteText.length ? 'edit-link-button' : 'add-notes-btn';
        const buttonText = noteText.length ? "EDIT" : "add notes"

        return (
            <div className={className}>
                {noteText.length ? 
                    (<div className="notes-markdown">
                        {!isExtra ? <h2>{title ? `${title} Notes`: "NOTES"}</h2> : null}
                        <Markdown escapeHtml={true} className="markdown" source={noteText} />
                    </div>)
                : null}

                <footer>
                    <button className={buttonClassName} onClick={this.openNoteEditorModal}>{buttonText}</button>
                </footer>
                {this.renderModal()}
            </div>
        );
    }
}
