'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import $ from 'jquery';

import DrawerMenu from '../../../pro/components/Widgets/DrawerMenu.react';

import AuthActions from '../../../actions/AuthActions';
import UserStore from '../../../stores/UserStore';
import ImgResized from '../../../components/Widgets/ImgResized.react';

import './OffCanvas.scss';

export default class OffCanvas extends Component {

    static propTypes =  {};
    static defaultProps = {};

    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            user: UserStore.getUser(),
            capabilities: UserStore.getCapabilities(),
        }
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onUserStoreChange = () => {
        this.setState({
            user: UserStore.getUser(),
            capabilities: UserStore.getCapabilities()
        });
    }

    canUsePublisher = () => {
        const { capabilities } = this.state;

        return capabilities && capabilities.publish;
    }

    logout = () => {
        AuthActions.deauthorize();

        $(window).scrollTop(0);

        this.context.router.push('/');
    }

    renderUserImage = (user) => {
        let avatar = user.gender === 'male' ? <i className="icon-male3" /> : <i className="icon-female3" />;

        if (user.image) {
            return (
                <Link to="/my-account/settings">
                    <ImgResized src={user.image} width={200} height={200} className="profile-image" />
                </Link>
            )
        } else if (user.name) {
            const words = user.name.split(' ');
            // first letter of first word
            const firstInitial = (words[0] && words[0][0]) || '';
            // first letter of last word
            const lastInitial = (words.length > 1 && words[words.length - 1] && words[words.length - 1][0]) || '';

            avatar = (firstInitial || lastInitial) ? <em>{firstInitial}{lastInitial}</em> : avatar;
        }

        return (
            <Link to="/my-account/settings" className="avatar">{avatar}</Link>
        );
    }

    render = () => {
        const { user, capabilities } = this.state;

        if (!user?.preferences) {
            return <span />
        }

        const { inhibit_add_swap_modes } = user?.preferences;
        const nutrRxName = user.practice_type == 'dietetics' ? 'Nutrition Prescription' : 'Nutrient Profile';

        return (
            <DrawerMenu button={<i className="icon-menu2" />}
                defaultClassName="side-menu-btn" modalClassName="side-menu-modal">
                <div className="side-navigation">
                    <header className="side-nav-header">
                        <div className='side-nav-profile'>
                            {this.renderUserImage(user)}
                            <Link to="/my-account/settings" className="side-nav-name">
                                <h6>{user.first_name || user.name || user.email}</h6>
                            </Link>
                            <button className="off-canvas-close">
                                <i className="icon-close-x" />
                            </button>
                        </div>
                    </header>

                    <ul className="side-nav-list">
                        <li><Link to="/my-account">Account Settings</Link></li>
                        {capabilities.purchase_membership ?
                            <li><Link to="/membership">Membership</Link></li>
                        : null}

                        {user ?
                            <>
                                <li><Link to="/apps-and-devices">Apps and Devices</Link></li>
                                <li><a onClick={this.logout}>Sign out</a></li>
                            </>
                        : null }
                    </ul>

                    <hr />

                    <div className="side-nav-list">
                        <header><Link to="/nutrition">{nutrRxName}</Link></header>
                    </div>
                    <div className="side-nav-list">
                        <header><Link to="/preferences">Meal Preferences</Link></header>
                    </div>
                    <div className="side-nav-list">
                        <header><Link to="/favorites">Favorites</Link></header>
                    </div>
                    <div className="side-nav-list">
                        <header><Link to={{ pathname: "/favorites" , query: { types: "collection" } }}>Collections</Link></header>
                    </div>
                    {!inhibit_add_swap_modes?.includes('meal-search') && !inhibit_add_swap_modes?.includes('browser') && <div className="side-nav-list">
                        <header><Link to="/search">Meal Search</Link></header>
                    </div>}
                    <div className="side-nav-list">
                        <header><Link to="/nutrition-academy">Nutrition Academy</Link></header>
                    </div>
                    <div className="side-nav-list">
                        <header><Link to="/food-banks">Food Bank Finder</Link></header>
                    </div>

                    <hr />

                    <footer className="side-nav-footer">
                        <ul className="side-nav-list">
                            <header className="logo">EatLove</header>
                            <li><Link to="/about" className="faq">About Us</Link></li>
                            <li><Link to="/learn" className="faq">Help</Link></li>
                            <li><a target="_blank" href="https://intercom.help/eatlove" className="about">FAQ</a></li>
                            <li><Link to="/references" className="faq">References</Link></li>
                            <li><a href="mailto:support@eatlove.is" className="contact">Contact Us</a></li>

                            <li><Link to="/blog">EatLove Blog</Link></li>
                            {this.canUsePublisher() ? <li><Link to="/publisher">Publishing Tools</Link></li> : null }
                            {user && this.state.user.role === 'admin' ? <li><Link to="/admin">Admin</Link></li> : null}
                        </ul>

                        <div className="off-canvas-social">
                            <div>
                                <a href="https://www.facebook.com/EatLove-470948183063093/timeline/" target="_blank">
                                    <i className="icon-facebook">
                                        <span className="assistive-text">Our Facebook Page</span>
                                    </i>
                                </a>
                            </div>
                            <div>
                                <a href="https://twitter.com/eatloveis" target="_blank">
                                    <i className="icon-twitter">
                                        <span className="assistive-text">Our Twitter Page</span>
                                    </i>
                                </a>
                            </div>
                            <div>
                                <a href="https://www.instagram.com/eatlove.pro/" target="_blank">
                                    <i className="icon-instagram">
                                        <span className="assistive-text">Our Instagram Page</span>
                                    </i>
                                </a>
                            </div>
                            <div>
                                <a href="https://www.pinterest.com/eatloveis/" target="_blank">
                                    <i className="icon-pinterest">
                                        <span className="assistive-text">Our Pinterest Page</span>
                                    </i>
                                </a>
                            </div>
                        </div>
                    </footer>
                </div>
            </DrawerMenu>
        );
    }
}
