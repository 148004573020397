'use strict'

import { render } from 'react-dom'
import React from 'react'

import SpringCarousel from '../../../components/Widgets/SpringCarousel.react';
import { getConfig } from '../../../utils/Env';
import Analytics from '../../../utils/Analytics';

import './PlanningTool.scss';

export default function PlanningTool(props) {
    let { carouselImages = [
        "https://static.chewba.info/images/pro-fitness/76c9b956-412d-4c21-998b-a903117d437f.png",
        "https://static.chewba.info/images/707cf393-e947-4bfc-b08e-13848950dba2.jpg",
        "https://static.chewba.info/images/64888a9b-fafd-415d-8e05-36624df2daf2.jpg",
        "https://static.chewba.info/images/76728669-d566-47a3-8cac-fc6e19d3ec06.jpg",
        "https://static.chewba.info/images/967be901-a4e8-48aa-ba5e-10390f4b51e9.jpg",
        "https://static.chewba.info/images/e768b506-1d63-41f0-9003-4539e1dd05c8.jpg",
    ], ctaHost } = props;


    ctaHost = ctaHost || ('https://' + getConfig('pro_host'));

    return (
        <div className="panel-planning-tool">
            <header>
                <h2>{props.moduleTitle || <span>A Mobile Meal Planning Tool in Your Client's Hands</span>}</h2>
                {props.subtext ? <p>{props.subtext}</p> : null}
            </header>

            <div className="screenshots">
                <SpringCarousel cardWidth={250} showPageDots={true}>
                    {carouselImages.map((src, i) => (<img key={i} src={src} />))}
                </SpringCarousel>
            </div>

            <div className="planning-tool-body">
                <ul>
                    <li>
                        <em>{props.firstBulletTitle || 'Customized Recipes'}</em>
                        <span>{props.firstBulletContent || 'On-demand meals that clients can plan ahead or organize in the moment and still meet their lifestyle and fitness goals.'}</span>
                    </li>
                    <li>
                        <em>Smart Grocery Lists</em>
                        <span>Maximize grocery dollars with ingredient-optimized shopping lists and save time with optional home delivery.</span>
                    </li>
                    <li>
                        <em>Restaurant Recommendations</em>
                        <span>Out of the house? No problem. EatLove locates the nearest restaurants with the smartest choices.</span>
                    </li>
                    <li>
                        <em>Real-Time Coaching</em>
                        <span>{props.lastBulletContent || 'Easy food logging to unlock personalized insights and track progress.'}</span>
                    </li>
                </ul>
            </div>

            <footer>
                {props.youfit && props?.email !== undefined && props.onChangeEmail && props.onClick ? (
                    <div>
                        <p className="instr">YOUFIT MEMBERS, ENTER YOUR EMAIL ADDRESS TO GET STARTED</p>
                        <div className="enter-email-form">
                            <input type="text" placeholder="Enter email linked to your YouFit account" value={props.email} onChange={props.onChangeEmail} />
                            <button className="start-trial-btn" onClick={props.onClick}>get started</button>
                        </div>
                    </div>
                )
                : <a href={ctaHost + '/new-account'} onClick={() => Analytics.clickSignUpButton('Planning Tool')}><button className="panel-cta-btn">Get Started Free • No Credit Card</button></a>}
            </footer>
        </div>
    );
}

