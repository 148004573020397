'use strict';

import AppDispatcher from '../dispatcher/AppDispatcher';
import ChunkLoadingConstants from '../constants/ChunkLoadingConstants';

const ChunkLoadingActions = {

    setIsChunkLoading(isChunkLoading) {
        AppDispatcher.handleViewAction({
            actionType: ChunkLoadingConstants.UPDATE_IS_CHUNK_LOADING,
            isChunkLoading,
        });
    }
}

export default ChunkLoadingActions;
