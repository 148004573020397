'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import Article from './DynamicPage/Article.react';
import Landing from './DynamicPage/Landing.react';

import "./DynamicPage.scss";
import "./Track.scss";

export default class DynamicPage extends Component {

    static contextTypes = {
        page: PropTypes.object,
        isPageLoading: PropTypes.bool,
        isPageLoaded: PropTypes.bool,
    }

    render = () => {
        const { page, isPageLoading, isPageLoaded } = this.context;

        return (
            <div>
                {!isPageLoading && page && page.page_type === 'article' ?
                    <Article page={page} />
                : null}

                {!isPageLoading && page && page.page_type === 'landing' ?
                    <Landing page={page} />
                : null}

                {isPageLoaded && !page ?
                    <div className="redirect-page">
                        <div className="el-fancy-box el-fonts">
                            <h5>Page Not found</h5>
                            <p className="p4">The link may have expired or been removed. Please email us at <a href="mailto:support@eatlove.is">support@eatlove.is</a></p>

                            <footer>
                                <Link to="/">
                                    <button className="el-medium-btn el-raspberry-btn">Return to EatLove</button>
                                </Link>
                            </footer>
                        </div>
                    </div>
                : null}

                {isPageLoading ?
                    <div><i className="icon-spinner2" /></div>
                : null}
            </div>
        );
    }
}
