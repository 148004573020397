'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import './Coachmark.scss';
import '../../FeedModals.scss';

export default class Coachmark extends Component {
    static propTypes = {
    };

    static contextTypes = {
        isMobile: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            drawer: false,
        };
    }

    componentDidMount = () => {
        this.setState({drawer: true});
    }

    closeModal = () => {
        this.setState({drawer: false}, () => setTimeout(this.props.closeModal, 333));
    }

    render = () => {
        const { drawer } = this.state;
        const { user, closeModal } = this.props;

        return (
            <Modal isOpen={true}
                closeModal={this.closeModal}
                onRequestClose={this.closeModal}
                className={drawer ? "feed-coachmark-modal open" : "feed-coachmark-modal"}
                overlayClassName="feed-modal-overlay"
                contentLabel="EatLove coachmark"
                closeTimeoutMS={250}>
                <div className="feed-coachmark-modal-container" onClick={this.closeModal}>
                    <div className="feed-coachmark feed-coachmark-styles" onClick={ev => ev.stopPropagation()}>
                        <h1>Welcome to <img src="https://static.chewba.info/images/header-logo-gray-green-2017-07-03.png" /></h1>

                        <p>The menu you’re about to see has been specifically created for you based on your nutrition prescription and has been designed to minimize food waste and keep costs low. </p>
                        <p>We’ll show you exactly what to cook, order or buy. Each week, review your plan. Swap and skip meals as you see fit.</p>

                        <h3>Take a quick video tour before you begin</h3>

                        <div className="iframe-container">
                            <iframe src="https://fast.wistia.net/embed/iframe/umjzbju9zv?seo=false&videoFoam=true" frameBorder="0" allowFullScreen allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                        </div>

                        <footer>
                            <button className="close-modal-btn" onClick={this.closeModal}>
                                close
                            </button>
                        </footer>
                    </div>
                </div>
            </Modal>
        );
    }
}
